import { useEffect } from "react"
import { getToken, obtainOutlookAndWPApiAccessTokens } from "../auth"
import { Express365Dispatch, useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { useDebouncedCallback } from "use-debounce"
import { setMessages } from "../store/MessageReducer"
import { useGetSolutionsQuery } from "../service/WorkPointWebApi"
import { safeLog } from "../util/debug"
import { showNotification } from "../store/NotificationReducer"
import { IntlShape, useIntl } from "react-intl"

const tokenRefreshThreshold = 5 * 60 * 1000 // 5 minutes

export const useAppContext = (
    officeReady: React.MutableRefObject<boolean>,
    lang?: React.MutableRefObject<string>
) => {
    const dispatch = useExpress365Dispatch()
    // const intl = useIntl()

    const { wpToken, wpTokenExpiry } = useExpress365Selector((state) => state.auth)

    const { tenantUrl } = useGetSolutionsQuery(undefined, {
        skip: !wpToken,
        selectFromResult: ({ data }) => {
            if (!data || data.length === 0) return { tenantUrl: null }
            return { tenantUrl: data?.[0].url! }
        }
    })

    const debouncedGetAccessToken = useDebouncedCallback(async () => {
        await obtainOutlookAndWPApiAccessTokens(dispatch, true)
    }, 500)

    useEffect(() => {
        if (!officeReady.current) {
            officeReady.current = true
            Office.onReady(() => {
                // Register event handlers and additional Office initialization logic.
                Office?.context?.mailbox?.addHandlerAsync(
                    Office.EventType.SelectedItemsChanged,
                    (argu: any) => {
                        getSelectedItems(dispatch)
                    },
                    (asyncResult) => {
                        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                            console.error(
                                "Failed to add SelectedItemsChanged handler:",
                                asyncResult.error.message
                            )
                        } else {
                            safeLog("SelectedItemsChanged Event handler added.")
                        }
                    }
                )

                /**
                 * Initial token fetch on app initialization.
                 */
                debouncedGetAccessToken()

                /**
                 * Set the selecetd email messages on app start, if selection made before app initialization.
                 */
                getSelectedItems(dispatch)
            })
        }
    }, [officeReady, debouncedGetAccessToken, dispatch])

    useEffect(() => {
        safeLog(
            `AUTH: Token check useEffect - Run: wpToken: ${Boolean(wpToken)}, wpTokenExpiry: ${wpTokenExpiry}, tenantUrl: ${tenantUrl}`
        )

        const checkAndRefreshTokens = async () => {
            safeLog("AUTH: Token check useEffect - Interval check")
            if (isTokenNearExpirationOrExpired(wpTokenExpiry)) await debouncedGetAccessToken()
        }

        // Try getting initial msal context going
        if (!!tenantUrl) getToken(["https://graph.microsoft.com/.default"])

        const tokenCheckInterval = setInterval(checkAndRefreshTokens, tokenRefreshThreshold)
        return () => clearInterval(tokenCheckInterval)
    }, [wpToken, wpTokenExpiry, tenantUrl, debouncedGetAccessToken, dispatch])

    return { authorized: !!wpToken, hasTenant: !!tenantUrl }
}

const isTokenNearExpirationOrExpired = (expiry?: number): boolean => {
    if (expiry === undefined || expiry === null) return true
    const expiresIn = expiry - new Date().getTime()
    return expiresIn < tokenRefreshThreshold
}

const getSelectedItems = (dispatch: Express365Dispatch) => {
    Office?.context?.mailbox?.getSelectedItemsAsync((asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            showNotification({
                message: `Could not get selected email message. It threw the following error: ${asyncResult.error.message}`, // @todo: Find a way to translate this.
                intent: "error"
            })
            return
        }

        let selectedItems = asyncResult.value

        if (selectedItems && selectedItems.length > 0) {
            selectedItems = selectedItems?.map((message) => {
                return {
                    ...message,
                    itemId: Office.context.mailbox.convertToRestId(
                        message.itemId,
                        Office.MailboxEnums.RestVersion.v2_0
                    )
                }
            })
        }

        dispatch(setMessages(selectedItems))
    })
}
