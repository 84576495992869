import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from "@reduxjs/toolkit/query/react"
import { getToken } from "../auth"

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    WebApi,
    extraOptions
) => {
    const graphEndpoint = process.env.REACT_APP_GRAPHAPI! // https://graph.microsoft.com/v1.0
    const urlObject = new URL((WebApi.getState() as any).solution.solution.url)
    //const sitePath = urlObject.pathname
    const tenantName = urlObject.host // wpfront.sharepoint.com
    const baseUrl = `${graphEndpoint}/sites/${tenantName}` // https://graph.microsoft.com/v1.0/sites/wpfront.sharepoint.com

    const rawBaseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
            const token = await getToken(["https://graph.microsoft.com/.default"])

            if (typeof token === "string" && token.length > 0) {
                headers.set("authorization", `Bearer ${token}`)
            }
            return headers
        }
    })
    return rawBaseQuery(args, WebApi, extraOptions)
}

export const graphWebApi = createApi({
    reducerPath: "graphWebApi",
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        getMessages: builder.query<any[], void>({
            query: () => ({
                url: "/me/messages/",
                method: "GET"
            })
        }),
        getItemsDelta: builder.query<
            { "@odata.deltaLink": string; value: { lastModifiedDateTime: string }[] },
            {
                listId: string
                itemId?: string
                siteRelativeUrl: string
                deltaToken?: string
            }
        >({
            query: ({ listId, itemId, siteRelativeUrl, deltaToken = "latest" }) => {
                const params = new URLSearchParams()
                params.set("token", deltaToken)
                if (itemId) params.set("$filter", `id eq '${itemId}'`)

                return {
                    url: `:${siteRelativeUrl}:/lists/${listId}/items/delta?${params.toString()}`,
                    method: "GET"
                }
            }
        })
    })
})

export const { useLazyGetItemsDeltaQuery, useGetMessagesQuery } = graphWebApi
