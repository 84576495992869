import { Input, tokens } from "@fluentui/react-components"
import { SearchRegular } from "@fluentui/react-icons"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const AnimatedSearchBar = ({
    showSearchBar,
    onChange,
    name
}: {
    showSearchBar: boolean
    onChange: (value: string) => void
    name?: string
}) => {
    const intl = useIntl()
    return (
        <ToggleAnimation $show={showSearchBar}>
            <Input
                contentBefore={<SearchRegular />}
                style={{
                    margin: "8px",
                    width: "-webkit-fill-available",
                    boxShadow: `${tokens.colorNeutralStroke1} 0px 1px 2px 2px`
                }}
                type="search"
                appearance="filled-lighter"
                placeholder={
                    intl.formatMessage({
                        defaultMessage: "Search",
                        id: "search"
                    }) +
                    " " +
                    (name ?? "")
                }
                onChange={(e) => onChange(e.target.value)}
                data-testid={"animated-search-bar"}
            />
        </ToggleAnimation>
    )
}

// The "$" infront of the "show" prop prevents it from being passed to the underlying React node
const ToggleAnimation = styled.div<{ $show: boolean }>`
    transition:
        height 0.3s ease,
        opacity 0.3s ease;
    height: ${({ $show }) => ($show ? "50px" : "0")};
    min-height: ${({ $show }) => ($show ? "50px" : "0")};
    opacity: ${({ $show }) => ($show ? "1" : "0")};
    overflow: hidden;
    width: -webkit-fill-available;
`
