export enum MyToolsButtonType {
    OpenWizard = "OpenWizard",
    Link = "Link",
    UserProcess = "UserProcess"
}

export enum ItemSelectionRequirement {
    None = "None",
    ExactlyOne = "ExactlyOne",
    AtLeastOne = "AtLeastOne",
    AtLeastTwo = "AtLeastTwo"
}

enum MyToolsButtonProcessExecutionType {
    Interactive = "Interactive",
    NonInteractive = "NonInteractive"
}

export interface IMyToolsButtonSetting {
    Title: string // "Email actionflow"
    TitleResource: { [key: string]: string } // { "1030": "E-mail actionflow", "1033": "Email actionflow" }
    Icon: string // "https://workpoint.azureedge.net/images/icons/color/Doc_32.png"
    PinToToolbar: boolean
    Id: string // "0886eefc-6f0c-4ee0-b134-61eaf1794f47"
    Type: MyToolsButtonType // WizardType eg: NewSiteListItem
    Wizard?: string // "DocumentProvisioning" in old Wizards and "1d6ad226-ac83-473b-be01-cb8978608236" in new Wizards
    Status?: string
    TargetAudience?: string
    BusinessModuleContentType?: string
    BusinessModuleId?: string
    EntitySiteList?: string
    EntitySiteListContentType?: string
    Folder?: string
    ItemSelectionRequirement?: ItemSelectionRequirement
    AllowedForSelecting?: any[]
    DisplayInApps: "WorkPoint" | "Express365"[]
    Parameters?: {
        [key: string]: any
    }
}

export interface IMyToolsGroupSetting {
    GroupTitle: string
    TitleResource: { [key: string]: string }
    Buttons: IMyToolsButtonSetting[]
    PinToToolbar: boolean
    GroupTargetAudience?: string
}

export interface IMyToolsSettings {
    BusinessModuleId: string
    Groups: IMyToolsGroupSetting[]
}

export interface IMyToolsUserProcessButtonSetting extends IMyToolsButtonSetting {
    ProcessId: string
    ProcessType: string
    ProcessExecutionType: MyToolsButtonProcessExecutionType
}

export interface IMyToolsLinkButtonSetting extends IMyToolsButtonSetting {
    Url: string
    Target: "_blank" | "_self" | "dialog"
}

export interface MyToolsParameters {
    BusinessModuleId?: string
    BusinessModuleContentType?: string
    EntitySiteList?: string
    EntitySiteListContentType?: string
    Folder?: string
    [key: string]: any
}

export function isMyToolsUserProcess(
    button: IMyToolsButtonSetting
): button is IMyToolsUserProcessButtonSetting {
    return button.Type === MyToolsButtonType.UserProcess
}

export function isMyToolsLink(button: IMyToolsButtonSetting): button is IMyToolsLinkButtonSetting {
    return button.Type === MyToolsButtonType.Link
}
