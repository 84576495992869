import {
    MessageBarGroup,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    MessageBarActions,
    Button,
    makeStyles,
    shorthands
} from "@fluentui/react-components"
import { DismissRegular } from "@fluentui/react-icons"
import { resetNotification } from "../store/NotificationReducer"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"

const useStyles = makeStyles({
    container: {
        ...shorthands.margin("10px"),
        display: "flex",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "calc(100% - 20px)"
    },
    messageBarGroup: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("10px"),
        ...shorthands.overflow("hidden")
    },
    messageBar: {
        width: "100%",
        ...shorthands.overflow("hidden")
    }
})

export const AppNotifications = () => {
    const styles = useStyles()
    const dispatch = useExpress365Dispatch()
    const { notifications } = useExpress365Selector((state) => state.notification)

    return (
        <div className={styles.container}>
            {notifications && notifications.length > 0 && (
                <MessageBarGroup animate="both" className={styles.messageBarGroup}>
                    {notifications
                        .filter((n) => !n.dismissed)
                        .map((notification) => {
                            return (
                                <MessageBar
                                    key={notification.message}
                                    intent={notification.intent}
                                    style={{ whiteSpace: "normal" }}
                                >
                                    <MessageBarBody>
                                        <MessageBarTitle>{notification.intent}</MessageBarTitle>
                                        {notification.message}
                                    </MessageBarBody>
                                    <MessageBarActions
                                        containerAction={
                                            <Button
                                                onClick={() => {
                                                    dispatch(resetNotification(notification.id))
                                                }}
                                                aria-label="dismiss"
                                                appearance="transparent"
                                                icon={<DismissRegular />}
                                            />
                                        }
                                    />
                                </MessageBar>
                            )
                        })}
                </MessageBarGroup>
            )}
        </div>
    )
}
