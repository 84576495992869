import {
    Button,
    tokens,
    BreadcrumbItem,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    BreadcrumbButton,
    MenuItem,
    MenuGroupHeader
} from "@fluentui/react-components"
import {
    AppsRegular,
    ArrowLeftRegular,
    CheckboxUncheckedRegular,
    CheckmarkRegular,
    ChevronRightRegular,
    FolderRegular,
    HomeRegular,
    MoreHorizontalRegular
} from "@fluentui/react-icons"
import {
    useNavigate,
    useLocation,
    matchPath,
    PathMatch,
    useParams,
    generatePath
} from "react-router"
import { Paths } from "../constants/routing"
import {
    useGetEntitiesQuery,
    useGetListsAndLibrariesQuery,
    useLazyGetEntityQuery
} from "../service/SharePointWebApi"
import { useIntl } from "react-intl"
import { useGetBusinessModulesQuery } from "../service/WorkPointWebApi"
import { getNumberLocaleFromStringLocale } from "../constants/LocalizationResource"
import styled from "styled-components"
import { Entity } from "../model/Item"
import { BusinessModuleIcon } from "./BusinessModuleIcon"
import { FlexRow } from "./ProcessDrawer"
import { getListIcon } from "../pages/EntityPage"
import { useExpress365Selector } from "../store/store"
import { useEffect, useState } from "react"

interface ItemLocationPair {
    listId: string
    itemId: number
}

interface PathWithName {
    path: string
    name: string
    icon?: any
    businessModuleIcon?: any
    businessModuleName?: string
    businessModulePath?: string
    key: number
}

export const BreadcrumbWithBack = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const [parentEntities, setParentEntities] = useState<
        { businessModuleId: string; entityId: string }[]
    >([])

    const { solution } = useExpress365Selector((state) => state.solution)

    const { data: businessModules } = useGetBusinessModulesQuery(undefined)

    const { businessModuleId, entityId, listId } = useParams()
    const { entity }: { entity: Entity } = useGetEntitiesQuery(
        { listId: businessModuleId! },
        {
            skip: !businessModuleId,
            selectFromResult: ({ data }) => ({
                entity: data?.ListData.Row.find((item) => item.ID === entityId)
            })
        }
    )

    const [trigger] = useLazyGetEntityQuery()

    useEffect(() => {
        const fetchParentEntities = async () => {
            if (entity && entity.wpItemLocation) {
                const entityHierarchy = getEntityHierarchy(entity?.wpItemLocation ?? "")
                if (entityHierarchy) {
                    let _parentEntities: { businessModuleId: string; entityId: string }[] = []
                    try {
                        const parentEntitiesPromises = entityHierarchy.map(
                            async (hierarchyItem) => {
                                const parentEntity = await trigger({
                                    listId: hierarchyItem.listId,
                                    id: hierarchyItem.itemId.toString()
                                }).unwrap()
                                return {
                                    businessModuleId: hierarchyItem.listId,
                                    entityId: parentEntity?.[0].ID
                                }
                            }
                        )

                        const resolvedParentEntities = await Promise.all(parentEntitiesPromises)
                        _parentEntities = resolvedParentEntities
                    } catch (error) {
                        console.error("Failed to fetch parent entity:", error)
                    }
                    setParentEntities(_parentEntities)
                }
            }
        }

        fetchParentEntities()
    }, [entity, trigger])

    const { listOrLibrary } = useGetListsAndLibrariesQuery(entity?.wpSite!, {
        skip: !entity,
        selectFromResult: ({ data }) => ({
            listOrLibrary: data?.find((item) => item.Id === listId)
        })
    })

    const getAllPathObjs = (path: string) => {
        const locations = path.split("/")
        let allPaths: string[] = []
        for (let i = 0; i < locations.length; i++) {
            allPaths.push(locations.slice(0, i + 1).join("/"))
        }
        allPaths = allPaths.filter((x) => x !== "/")
        if (allPaths[0] === "") allPaths[0] = Paths.HomeDirect

        const addParentBusinessModulePaths = (paths: string[]) => {
            for (let path of paths) {
                const match = matchPath(Paths.Entity, path)
                if (match && parentEntities.length > 0) {
                    const entityPaths: string[] = []
                    for (let parentEntity of parentEntities) {
                        if (parentEntity.businessModuleId && parentEntity.entityId) {
                            entityPaths.push(generatePath(Paths.Entity, parentEntity))
                        }
                    }
                    if (entityPaths.length > 0) {
                        allPaths.splice(2, 3, ...entityPaths)
                    }
                }
            }
        }

        addParentBusinessModulePaths([...allPaths])

        const matchedPaths: { key: string; match: PathMatch<any> }[] = []
        allPaths.forEach((path) => {
            for (let key of Object.keys(Paths)) {
                if (Paths[key as keyof typeof Paths] === "/*") continue
                const match = matchPath(Paths[key as keyof typeof Paths], path)
                if (match?.params?.folderPath) {
                    const folderPaths = match.params.folderPath.split("/")
                    for (let i = 4; i < folderPaths.length; i++) {
                        const folderPath = folderPaths.slice(0, i + 1).join("/")
                        const uri = folderPaths.slice(0, i + 1).join("%2F")
                        const newPathname = match.pathname.split(uri)[0] + uri
                        matchedPaths.push({
                            key,
                            match: {
                                ...match,
                                pathname: newPathname,
                                params: { ...match.params, folderPath }
                            }
                        })
                    }
                } else if (match) {
                    matchedPaths.push({ key, match })
                    break
                }
            }
        })

        const lcid = getNumberLocaleFromStringLocale(Office?.context?.displayLanguage || "en-US")

        const namesWithIcons: Omit<PathWithName, "path" | "key">[] = matchedPaths.map((path) => {
            let name: string
            let icon: any
            let businessModuleIcon: any
            let businessModuleName: string | undefined
            let businessModulePath: string | undefined
            switch (path.match.pattern.path) {
                case Paths.HomeDirect:
                    name =
                        intl.formatMessage({
                            defaultMessage: "Home",
                            id: "home"
                        }) + (solution ? ` - ${solution.name}` : "")
                    icon = <HomeRegular />
                    break
                case Paths.Solutions:
                    name = intl.formatMessage({
                        defaultMessage: "All Solutions",
                        id: "allSolutions"
                    })
                    icon = <AppsRegular />
                    break
                case Paths.BusinessModules:
                    const businessModule = businessModules?.find(
                        (b) => b.Id === path.match.params.businessModuleId
                    )
                    name =
                        (lcid && businessModule?.TitleResources?.[lcid]) ||
                        businessModule?.Title ||
                        ""
                    icon = <BusinessModuleIcon iconUrl={businessModule?.IconUrl ?? ""} />
                    break
                case Paths.Entity:
                    const entityBusinessModule = businessModules?.find(
                        (b) => b.Id === path.match.params.businessModuleId
                    )
                    name = entity?.Title || ""
                    businessModuleIcon = (
                        <BusinessModuleIcon iconUrl={entityBusinessModule?.IconUrl ?? ""} />
                    )
                    businessModuleName =
                        (lcid && entityBusinessModule?.TitleResources?.[lcid]) ||
                        entityBusinessModule?.Title ||
                        ""
                    businessModulePath = generatePath(Paths.BusinessModules, {
                        businessModuleId: path.match.params.businessModuleId ?? ""
                    })
                    break
                case Paths.EntityListView:
                    name = listOrLibrary?.Title || ""
                    icon = getListIcon(listOrLibrary?.BaseTemplate ?? 0)
                    break
                case Paths.EntityListViewFolder:
                    const names = path.match.params.folderPath?.split("/")
                    name = decodeURIComponent(names?.[names?.length - 1] ?? "") || ""
                    icon = <FolderRegular />
                    break
                default:
                    name = "Missing '" + path.key + "' title"
                    icon = <CheckboxUncheckedRegular />
                    break
            }
            return {
                name,
                icon,
                businessModuleIcon,
                businessModuleName,
                businessModulePath
            }
        })

        const pathObjs: PathWithName[] = []

        for (let i = 0; i < matchedPaths.length; i++) {
            pathObjs.push({
                path: matchedPaths[i].match.pathname,
                ...namesWithIcons[i],
                key: i + 1
            })
        }

        return pathObjs
    }

    const items = getAllPathObjs(location.pathname)

    return (
        <BreadcrumbContainer>
            <Button
                icon={<ArrowLeftRegular />}
                onClick={() => navigate(-1)}
                style={{ margin: "5px", borderWidth: "0px" }}
            />
            <VerticalDivider />
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Button
                        icon={<MoreHorizontalRegular />}
                        appearance="subtle"
                        style={{ marginLeft: "5px" }}
                    />
                </MenuTrigger>
                <MenuPopover style={{ minWidth: "320px" }}>
                    <MenuGroupHeader style={{ marginLeft: "-8px", marginTop: "-8px" }}>
                        Path
                    </MenuGroupHeader>
                    <MenuList>
                        {items.map((item, index) => {
                            return (
                                <FlexRow key={index}>
                                    {index === items.length - 1 ? (
                                        <CheckmarkRegular style={{ margin: "0px 12px 0px 4px" }} />
                                    ) : (
                                        <div style={{ width: "30px" }} />
                                    )}
                                    {item.businessModuleIcon && (
                                        <MenuItem
                                            icon={item.businessModuleIcon}
                                            onClick={() =>
                                                item.businessModulePath &&
                                                navigate(item.businessModulePath)
                                            }
                                            title={item.businessModuleName}
                                        />
                                    )}
                                    <MenuItem
                                        key={index}
                                        onClick={() => navigate(item.path)}
                                        icon={item.icon}
                                        style={{
                                            width: item.businessModuleIcon ? "220px" : "250px",
                                            maxWidth: item.businessModuleIcon ? "220px" : "250px"
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginLeft: item.businessModuleIcon
                                                    ? undefined
                                                    : "8px",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                width: "200px"
                                            }}
                                            title={item.name}
                                        >
                                            {item.name}
                                        </div>
                                    </MenuItem>
                                </FlexRow>
                            )
                        })}
                    </MenuList>
                </MenuPopover>
            </Menu>
            <ChevronRightRegular style={{ margin: "0px 4px" }} />
            <BreadcrumbItem>
                <BreadcrumbButton current>
                    <TruncatedText>{items[items.length - 1].name}</TruncatedText>
                </BreadcrumbButton>
            </BreadcrumbItem>
        </BreadcrumbContainer>
    )
}

/**
 * Adds dashes to a stripped Guid string.
 *
 * @param {string} guidCandidateString The Guid string to add dashes to.
 */
const ensureGuidDashes = (guidCandidateString: string): string | null => {
    if (typeof guidCandidateString === "string" && guidCandidateString.trim() !== "") {
        const dashesPlacement = guidCandidateString.replace(
            /([0-9a-f]{8})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{12})/g,
            "$1-$2-$3-$4-$5"
        )

        // Superflous dashes removed
        const singleDashesOnly = dashesPlacement.replace(/-+/g, "-")

        return singleDashesOnly
    }

    // Impossible guid, bail out.
    return null
}

/**
 * Gets an array of listId and itemId pairs for the complete hierarchy of the provided entitys itemLocation.
 * @warning Strips the tenant Guid at the start.
 * @warning Adds dashes to the guid, so they conform to being used in our ListService.
 * @param {string} itemLocation
 */
const getEntityHierarchy = (itemLocation: string): ItemLocationPair[] | null => {
    if (typeof itemLocation !== "string" || itemLocation === "") {
        return null
    }

    let itemLocationStringParts: string[] = itemLocation
        .split(";")
        .filter((part) => part && part.trim() !== "")

    // Remove tenant id.
    itemLocationStringParts.shift()

    const entityParentPairs: any[] = itemLocationStringParts
        .reduce((result: Array<string[]>, value: string, index: number, array: string[]) => {
            if (index % 2 === 0) result.push(array.slice(index, index + 2))
            return result
        }, [])
        .map((r) => ({ listId: ensureGuidDashes(r[0]), itemId: parseInt(r[1]) }))

    return entityParentPairs
}

const TruncatedText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const BreadcrumbContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    overflow-x: hidden;
    overflow-y: hidden;
    justify-content: start;
    align-items: center;
`

const VerticalDivider = styled.div`
    height: 24px;
    border-left: 1px solid ${tokens.colorNeutralStroke1};
`
