import React, { useRef } from "react"
import "./App.css"
import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom"
import { Home } from "./pages/Home"
import { SolutionsPage } from "./pages/SolutionsPage"
import { Paths } from "./constants/routing"
import { BusinessModulePage } from "./pages/BusinessModulePage"
import { EntityPage } from "./pages/EntityPage"
import { AppNotifications } from "./components/AppNotifications"
import { useAppContext } from "./hooks/appContext"
import { EntityListPage } from "./pages/EntityListPage"
import { IntlProvider } from "react-intl"
import { Layout } from "./components/Layout"
import { StartUp } from "./pages/StartUpPage"

function App() {
    /**
     * Guard to make sure Office.onReady is only called once.
     * React Strict mode forces the app to render twice in development mode.
     */
    const officeReadyCalled = useRef(false)

    const { authorized, hasTenant } = useAppContext(officeReadyCalled)

    // Internal App Routing
    const router = createBrowserRouter(routes)

    let messages

    switch (Office?.context?.displayLanguage || "en-US") {
        case "da-DK":
            messages = require("./locales/da-DK.json")
            break
        default:
            messages = require("./locales/en.json")
    }

    const loading = !authorized || !hasTenant

    return (
        <IntlProvider
            defaultLocale="en"
            messages={messages}
            locale={Office?.context?.displayLanguage || "en-US"}
        >
            <div
                className="App"
                data-testid="express-container"
                style={{
                    height: "100vh",
                    width: "100vw"
                }}
            >
                {loading ? (
                    <StartUp authorized={authorized} hasTenant={hasTenant} />
                ) : (
                    <RouterProvider router={router} />
                )}
                <AppNotifications />
            </div>
        </IntlProvider>
    )
}

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: Paths.Solutions,
                element: <SolutionsPage />
            },
            {
                path: Paths.BusinessModules,
                element: <BusinessModulePage />
            },
            {
                path: Paths.Entity,
                element: <EntityPage />
            },
            {
                path: Paths.EntityListView,
                element: <EntityListPage />
            },
            {
                path: Paths.EntityListViewFolder,
                element: <EntityListPage />
            }
        ]
    }
]

export default App
