import { useEffect, useState } from "react"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { useLazyGetItemsDeltaQuery } from "../service/GraphWebApi"
import { sharePointWebApi } from "../service/SharePointWebApi"
import { safeLog } from "../util/debug"

const extractDeltaLink = (deltaLink: any) => {
    if (typeof deltaLink === "string") {
        const newDeltaToken = new URL(deltaLink!).searchParams.get("token")

        if (typeof newDeltaToken === "string") return newDeltaToken
    }

    return null
}

export interface IDeltaContext {
    listId: string
    siteRelativeUrl?: string
    itemId?: string
}

/**
 * Hook to check for changes in a business module or lists, requiring a re-fetch.
 * @param businessModuleContext Context for the business module.
 * @param listContext Context for the list.
 * @param interval Miliseconds between change checks, defaulting to 60000 (1 minute).
 */
export const useContextDeltaCheck = (
    businessModuleContext: IDeltaContext | null,
    listContext: IDeltaContext | null,
    interval: number = 60000
) => {
    const dispatch = useExpress365Dispatch()
    const [trigger] = useLazyGetItemsDeltaQuery()

    const [businessModuleContextDeltaToken, setBusinessModuleContextDeltaToken] = useState<
        string | null
    >(null)
    const [listContextDeltaToken, setListContextDeltaToken] = useState<string | null>(null)

    const { url } = useExpress365Selector((state) => state.solution.solution!)
    const solutionRelativeUrl = new URL(url)?.pathname

    useEffect(() => {
        const fetchInitialDeltaToken = async (
            context: IDeltaContext,
            setDeltaToken: (token: string) => void
        ) => {
            const { listId, itemId, siteRelativeUrl } = context

            const actualSiteRelativeUrl = siteRelativeUrl ? siteRelativeUrl : solutionRelativeUrl

            const response = await trigger({
                listId,
                itemId,
                siteRelativeUrl: actualSiteRelativeUrl
            })
            const newDeltaToken = extractDeltaLink(response.data?.["@odata.deltaLink"])
            if (newDeltaToken) setDeltaToken(newDeltaToken)
        }

        if (businessModuleContext && !businessModuleContextDeltaToken) {
            fetchInitialDeltaToken(businessModuleContext, setBusinessModuleContextDeltaToken)
        }

        if (listContext && !listContextDeltaToken) {
            fetchInitialDeltaToken(listContext, setListContextDeltaToken)
        }

        const deltaCallback = async (
            context: IDeltaContext,
            deltaToken: string | null,
            setDeltaToken: (token: string) => void
        ) => {
            if (!deltaToken) return

            const { listId, itemId, siteRelativeUrl } = context

            const actualSiteRelativeUrl = siteRelativeUrl ? siteRelativeUrl : solutionRelativeUrl

            const { data } = await trigger({
                listId,
                itemId,
                siteRelativeUrl: actualSiteRelativeUrl,
                deltaToken
            })

            if (data && data.value && data.value.length > 0) {
                const newDeltaToken = extractDeltaLink(data["@odata.deltaLink"])
                if (newDeltaToken) setDeltaToken(newDeltaToken)

                const type = itemId ? "Entities" : "ListItems"
                safeLog(
                    `${type} were changed, refreshing stale cache and reloading (key: ${listId}).`
                )
                dispatch(
                    sharePointWebApi.util.invalidateTags([
                        { type: "Entities", id: listId },
                        { id: listId, type: "ListItems" }
                    ])
                )
            }
        }

        let entityDeltaCheckCallback: NodeJS.Timer, listItemsDeltaCheckCallback: NodeJS.Timer

        if (businessModuleContext) {
            entityDeltaCheckCallback = setInterval(() => {
                deltaCallback(
                    businessModuleContext,
                    businessModuleContextDeltaToken,
                    setBusinessModuleContextDeltaToken
                )
            }, interval)
        }

        if (listContext) {
            listItemsDeltaCheckCallback = setInterval(() => {
                deltaCallback(listContext, listContextDeltaToken, setListContextDeltaToken)
            }, interval)
        }

        const handleProcessMessage = (event: MessageEvent): void => {
            if (event.origin === `${window.location.protocol}//${window.location.host}`) {
                const messageData = event.data

                if (
                    messageData.type === "express365" &&
                    messageData.action === "cache-invalidation-check"
                ) {
                    if (businessModuleContext)
                        deltaCallback(
                            businessModuleContext,
                            businessModuleContextDeltaToken,
                            setBusinessModuleContextDeltaToken
                        )
                    if (listContext)
                        deltaCallback(listContext, listContextDeltaToken, setListContextDeltaToken)
                }
            }
        }

        window.addEventListener("message", handleProcessMessage)

        return () => {
            if (businessModuleContext) clearInterval(entityDeltaCheckCallback)
            if (listContext) clearInterval(listItemsDeltaCheckCallback)
            window.removeEventListener("message", handleProcessMessage)
        }
    }, [
        businessModuleContext,
        listContext,
        businessModuleContextDeltaToken,
        listContextDeltaToken,
        trigger,
        dispatch,
        interval,
        solutionRelativeUrl
    ])

    return { businessModuleContextDeltaToken, listContextDeltaToken }
}
