import { Spinner, Table, TableBody, TableCell, TableRow, Text } from "@fluentui/react-components"
import { CheckmarkFilled } from "@fluentui/react-icons"
import styled from "styled-components"
import { useIntl } from "react-intl"

export const StartUp = ({ authorized, hasTenant }: { authorized: boolean; hasTenant: boolean }) => {
    const intl = useIntl()
    return (
        <FlexContainer>
            <img alt="wp-logo" src={"/WP_3D_Cube.png"} height={"140px"} />
            <Text weight="semibold" size={500} style={{ margin: "15px 0px" }}>
                WorkPoint365
            </Text>
            <Text>
                {intl.formatMessage({
                    defaultMessage: "Getting things ready for you...",
                    id: "gettingThingsReady"
                })}
            </Text>
            <Table style={{ width: "auto", margin: "10px" }}>
                <TableBody>
                    <LoadingItem
                        text={intl.formatMessage({
                            id: "authorizing",
                            defaultMessage: "Authorizing"
                        })}
                        loading={!authorized}
                    />
                    <LoadingItem
                        text={intl.formatMessage({
                            defaultMessage: "Identifying Tenant",
                            id: "identifyingTenant"
                        })}
                        loading={!hasTenant}
                    />
                </TableBody>
            </Table>
        </FlexContainer>
    )
}

const LoadingItem = ({ text, loading }: { text: string; loading: boolean }) => {
    return (
        <TableRow style={{ border: "none", height: "auto" }}>
            <TableCell
                style={{
                    width: "30px",
                    padding: "0px",
                    height: "25px",
                    display: "flex",
                    flexWrap: "wrap",
                    placeContent: "center"
                }}
            >
                {loading ? <Spinner size="extra-tiny" /> : <CheckmarkFilled />}
            </TableCell>
            <TableCell style={{ textAlign: "left", height: "25px" }}>
                <Text>{text}</Text>
            </TableCell>
        </TableRow>
    )
}

const FlexContainer = styled.div`
    padding-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
