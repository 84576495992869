import React from "react"
import { Spinner, Text } from "@fluentui/react-components"
import { ArrowSwapRegular, HomeRegular } from "@fluentui/react-icons"
import { useEffect } from "react"
import { Paths } from "../constants/routing"
import { IconButton } from "../pages/Home"
import { useGetSolutionsQuery } from "../service/WorkPointWebApi"
import { selectSolution } from "../store/SolutionReducer"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { useNavigate } from "react-router"
import styled from "styled-components"
import { HeaderContainer } from "../pages/SolutionsPage"
import { Actions } from "./Actions"

export const SolutionRow = () => {
    const dispatch = useExpress365Dispatch()
    const { solution } = useExpress365Selector((state) => state.solution)
    const navigate = useNavigate()
    const { data, isFetching } = useGetSolutionsQuery(undefined, {
        skip: typeof solution?.url !== "undefined"
    })

    useEffect(() => {
        if (data && typeof !solution?.url) {
            if (data.length === 1) {
                selectSolution(dispatch, data[0])
            } else {
                navigate(Paths.Solutions)
            }
        }
    }, [data, dispatch, solution, navigate])

    return (
        <>
            <HeaderContainer>
                <SolutionRowContainer>
                    {isFetching && <Spinner />}
                    {!isFetching && (
                        <>
                            <HomeRegular style={{ fontSize: "20px" }} />
                            <Text
                                weight={"semibold"}
                                data-testid="solution-name"
                                style={{ marginRight: "auto", marginLeft: "10px" }}
                            >
                                {solution?.name}
                            </Text>
                            <Actions />
                            <IconButton
                                icon={<ArrowSwapRegular />}
                                onClick={() => navigate(Paths.Solutions)}
                            />
                            {/* <IconButton icon={<SearchRegular />} onClick={toggleSearchBar} /> */}
                        </>
                    )}
                </SolutionRowContainer>
            </HeaderContainer>
        </>
    )
}

const SolutionRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
    padding: 4px 4px 4px 8px;
`
