export type LocalizationResource = {
    [key: string]: string
}

export const locales: LocalizationResource = {
    1025: "ar-sa",
    1026: "bg-bg",
    1027: "ca-es",
    1028: "zh-tw",
    1029: "cs-cz",
    1030: "da-dk",
    1031: "de-de",
    1032: "el-gr",
    1033: "en-us",
    1035: "fi-fi",
    1036: "fr-fr",
    1037: "he-il",
    1038: "hu-hu",
    1040: "it-it",
    1041: "ja-jp",
    1042: "ko-kr",
    1043: "nl-nl",
    1044: "nb-no",
    1045: "pl-pl",
    1046: "pt-br",
    1048: "ro-ro",
    1049: "ru-ru",
    1050: "hr-hr",
    1051: "sk-sk",
    1053: "sv-se",
    1054: "th-th",
    1055: "tr-tr",
    1057: "id-id",
    1058: "uk-ua",
    1060: "sl-si",
    1061: "et-ee",
    1062: "lv-lv",
    1063: "lt-lt",
    1066: "vi-vn",
    1068: "az-latn-az",
    1069: "eu-es",
    1071: "mk-mk",
    1081: "hi-in",
    1086: "ms-my",
    1087: "kk-kz",
    1106: "cy-gb",
    1110: "gl-es",
    1164: "prs-af",
    2052: "zh-cn",
    2070: "pt-pt",
    2074: "sr-latn-cs",
    2108: "ga-ie",
    3082: "es-es",
    5146: "bs-latn-ba",
    9242: "sr-latn-rs",
    10266: "sr-cyrl-rs"
}

/**
 * Given a string locale string, returns the matching SharePoint locale id.
 *
 * @default 1033
 *
 * @param {string} localeString
 */
export const getNumberLocaleFromStringLocale = (localeString: string): number => {
    try {
        const loweredLocaleString = localeString.toLowerCase()

        const matches = Object.keys(locales).filter((key) => locales[key] === loweredLocaleString)

        if (matches && matches.length > 0) {
            return parseInt(matches[0])
        }

        throw new Error("No locale matching.")
    } catch (exception) {
        console.warn(
            `Could not get locale from string: '${localeString}'. It threw the following error: '${exception}'. Defaulting to en-US locale (1033) instead.`
        )
    }

    return 1033
}
