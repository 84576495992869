import { createSelector, createSlice } from "@reduxjs/toolkit"
import { BusinessModule, BusinessModuleHierarchyType } from "../model/BusinessModule"
import { Express365RootState } from "./store"

export type BusinessModuleState = {
    businessModules: BusinessModule[]
    businessModuleHierarchy: BusinessModuleHierarchyType[]
}

const initialState: BusinessModuleState = {
    businessModules: [],
    businessModuleHierarchy: []
}

const businessModuleSlice = createSlice({
    name: "businessModule",
    initialState,
    reducers: {
        /**
         * @todo: Refactor this as a selector to use from the WorkPointWebApi state.
         */
        setBusinessModules: (state, action) => {
            state.businessModules = action.payload

            const list = action.payload.map((item: BusinessModule) => ({
                ...item,
                children: []
            })) as BusinessModuleHierarchyType[]
            let map: { [id: string]: number } = {},
                node,
                roots = [],
                i

            for (i = 0; i < list.length; i += 1) {
                map[list[i].Id] = i
            }

            for (i = 0; i < list.length; i += 1) {
                node = list[i] as BusinessModuleHierarchyType
                if (node.Parent !== null) {
                    list[map[node.Parent]]
                        ? list[map[node.Parent]].children.push(node)
                        : Function.prototype() /** @todo: Dispatch 'showNotificaiton'*/
                } else {
                    roots.push(node)
                }
            }
            state.businessModuleHierarchy = roots
        },
        resetBusinessModules: (state) => {
            state.businessModules = []
            state.businessModuleHierarchy = []
        }
    }
})

const selectBusinessModules = (state: Express365RootState) => state.businessModule.businessModules

export const selectedBusinessModule = createSelector(
    [selectBusinessModules, (_: Express365RootState, businessModuleId: string) => businessModuleId],
    (businessModules: BusinessModule[], businessModuleId: string) =>
        businessModules.find((businessModule) => businessModule.Id === businessModuleId)
)

export const { setBusinessModules, resetBusinessModules } = businessModuleSlice.actions

export const businessModuleReducer = businessModuleSlice.reducer
