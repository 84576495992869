/**
 * Get Office open client-side URI-scheme URL.
 *
 * Valid document URL's are known Word, Excel, PowerPoint, Visio, Project files and Sigma Office 365.
 *
 * @param documentUrl URL to open document.
 */
export function getOfficeClientProgramOpenURIScheme(documentUrl: string): string | null {
    const documentUrlParts: string[] = documentUrl.split(".")
    const extension: string = documentUrlParts[documentUrlParts.length - 1]

    let uriScheme: string | null = null

    switch (extension.toLowerCase()) {
        case "docx":
        case "docm":
        case "dotx":
        case "dotm":
        case "doc":
        case "dot":
        case "docb":
            uriScheme = "ms-word:ofe|u|"
            break
        case "xlsx":
        case "xlsm":
        case "xltx":
        case "xltm":
        case "xls":
        case "xlt":
        case "xlm":
        case "xlsb":
        case "xlw":
        case "csv":
            uriScheme = "ms-excel:ofe|u|"
            break
        case "pptx":
        case "pptm":
        case "potx":
        case "potm":
        case "ppam":
        case "ppsx":
        case "ppsm":
        case "sldx":
        case "sldm":
        case "ppt":
        case "pot":
        case "pps":
            uriScheme = "ms-powerpoint:ofe|u|"
            break
        case "vsd":
        case "vss":
        case "vst":
        case "vdw":
        case "vdx":
        case "vsx":
        case "vtx":
        case "vsdx":
        case "vsdm":
        case "vssx":
        case "vssm":
        case "vstx":
        case "vstm":
        case "vsl":
            uriScheme = "ms-visio:ofe|u|"
            break
        case "c2k":
        case "mpc":
        case "mpd":
        case "mpp":
        case "mppx":
        case "mpp_":
        case "mpt":
        case "mpv":
        case "mpw":
        case "mpx":
        case "mspdi":
        case "mxm":
            uriScheme = "ms-project:ofe|u|"
            break
        case "sig":
            uriScheme = "sigmaoffice365://file="
    }

    if (uriScheme === null) return null

    return uriScheme
}
