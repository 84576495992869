import { createSlice } from "@reduxjs/toolkit"
import { AsyncModelState } from "../model/AsyncModelState"
import { Express365RootState } from "./store"

/**
 * @todo: Handle multiple token states for different resources.
 */
export type AuthState = AsyncModelState & {
    wpToken: string | undefined
    wpTokenExpiry: number | undefined
    outlookSSOToken: string | undefined
}

const initialState: AuthState = {
    wpToken: undefined,
    wpTokenExpiry: undefined,
    outlookSSOToken: undefined,
    status: undefined,
    errorMessage: undefined
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setWPToken: (state, { payload }) => {
            // state.status = RequestState.fulfilled
            state.wpToken = payload
            state.wpTokenExpiry = getTokenExpiry(payload)
        },
        expireWpToken: (state) => {
            state.wpTokenExpiry = new Date().getTime()
        },
        expireWpTokenSoon: (state) => {
            state.wpTokenExpiry = new Date().getTime() + 6 * 60 * 1000
        },
        setOutlookSSOToken: (state, { payload }) => {
            state.outlookSSOToken = payload
        },
        clearWPToken: (state) => {
            state.wpToken = undefined
        },
        clearOutlookSSOToken: (state) => {
            state.outlookSSOToken = undefined
        }
    }
})

export const authReducer = authSlice.reducer

export const wpApiReadySelector = (state: Express365RootState) =>
    typeof state.auth.wpToken === "string" && state.auth.wpToken.length > 0

export const { setWPToken, clearWPToken, setOutlookSSOToken, expireWpToken, expireWpTokenSoon } =
    authSlice.actions

function getTokenExpiry(token: string): number | undefined {
    try {
        const base64Url = token.split(".")[1] // JWT is in the form of header.payload.signature
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join("")
        )

        const payload = JSON.parse(jsonPayload)
        if (!payload.exp) {
            console.error("Expiration claim (exp) is missing in the JWT token")
            return undefined
        }

        return payload.exp * 1000
    } catch (error) {
        console.error("Error decoding JWT token for expiration:", error)
        return undefined
    }
}
