import { createSlice } from "@reduxjs/toolkit"

const initialState: MessageState = {
    selectedMessages: []
}

export type MessageState = {
    selectedMessages: OutlookMessage[]
}

export interface OutlookMessage {
    itemId: string
    subject: string
    itemType: string
    itemMode: string
    internetMessageId: string
    conversationId: string
    hasAttachments: string
}

const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setMessages: (state, { payload }) => {
            state.selectedMessages = payload
        }
    }
})

export const messageReducer = messageSlice.reducer

export const { setMessages } = messageSlice.actions
