import { SearchRegular } from "@fluentui/react-icons"
import { BreadcrumbWithBack } from "../components/BreadcrumbWithBack"
import { HeaderContainer, TitleContainer } from "./SolutionsPage"
import {
    Avatar,
    createTableColumn,
    TableCellLayout,
    TableColumnDefinition,
    Text
} from "@fluentui/react-components"
import { IconButton, PageContainer } from "./Home"
import { Paths } from "../constants/routing"
import { generatePath, useNavigate, useParams } from "react-router"
import { FC, PropsWithChildren, useEffect, useState } from "react"
import { useGetEntitiesQuery, useLazyGetEntitiesQuery } from "../service/SharePointWebApi"
import { useDebouncedCallback } from "use-debounce"
import { useGetBusinessModulesQuery } from "../service/WorkPointWebApi"
import { BusinessModule } from "../model/BusinessModule"
import { getNumberLocaleFromStringLocale } from "../constants/LocalizationResource"
import { BusinessModuleIcon } from "../components/BusinessModuleIcon"
import { TableDisplay } from "../components/TableDisplay"
import { Actions } from "../components/Actions"
import styled from "styled-components"
import { AnimatedSearchBar } from "../components/AnimatedSearchBar"
import { useContextDeltaCheck } from "../hooks/deltaChecks"

export const BusinessModulePage = () => {
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSetSearchTerm = useDebouncedCallback(setSearchTerm, 400)
    const [columns, setColumns] = useState<TableColumnDefinition<any>[]>()
    const [data, setData] = useState<{ rows: any[]; nextHref: string }>({
        rows: [],
        nextHref: ""
    })
    const params = useParams()

    const { businessModuleId } = params as { businessModuleId: string }

    const { businessModule }: { businessModule: BusinessModule } = useGetBusinessModulesQuery(
        undefined,
        {
            selectFromResult: ({ data }) => ({
                businessModule: data?.find((item) => item.Id === businessModuleId)!
            })
        }
    )

    const [showSearchBar, setShowSearchBar] = useState(false)
    const toggleSearchBar = () => {
        setShowSearchBar((prev) => !prev)
    }

    const lcid = getNumberLocaleFromStringLocale(Office.context.displayLanguage)
    useEffect(() => {
        if (businessModule) {
            setColumns([
                createTableColumn<any>({
                    columnId: "name",
                    renderHeaderCell: () => (
                        <Text weight="semibold">
                            {(lcid && businessModule.EntityNameResource?.[lcid]) ||
                                businessModule?.EntityName}
                        </Text>
                    ),
                    renderCell: (item) => (
                        <TableCellLayout
                            media={
                                <Avatar
                                    color="colorful"
                                    name={item.Title}
                                    style={{ borderRadius: "0px" }}
                                />
                            }
                        >
                            {item.Title}
                        </TableCellLayout>
                    )
                })
            ])
        }
    }, [businessModule, lcid])

    const { data: initialData, isLoading } = useGetEntitiesQuery({
        listId: businessModuleId,
        search: searchTerm
    })

    const [getEntities, { isFetching }] = useLazyGetEntitiesQuery()

    const [allHrefs, setAllHrefs] = useState<string[]>([])

    useEffect(() => {
        if (initialData) {
            if (allHrefs.length > 0) {
                const promises = []
                for (const href of allHrefs) {
                    const promise = getEntities(
                        {
                            listId: businessModuleId!,
                            search: searchTerm,
                            nextHref: href
                        },
                        true
                    ).unwrap()

                    promises.push(promise)
                }
                Promise.all(promises).then((results) => {
                    const rows = results.flatMap((result) => result.ListData.Row)
                    setData({
                        rows: [...initialData.ListData.Row, ...rows],
                        nextHref: results[results.length - 1].ListData.NextHref
                    })
                })
            } else {
                setData({
                    rows: initialData.ListData.Row,
                    nextHref: initialData.ListData.NextHref
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData])

    useContextDeltaCheck({ listId: businessModuleId }, null, 30000)

    return (
        <PageContainer>
            <HeaderContainer>
                <TitleContainer>
                    <IconContainer>
                        <BusinessModuleIcon iconUrl={businessModule?.IconUrl} />
                    </IconContainer>
                    <HeaderTitleText>
                        {(lcid && businessModule?.TitleResources?.[lcid]) || businessModule?.Title}
                    </HeaderTitleText>
                    <ActionsContainer>
                        <Actions />
                        <IconButton
                            icon={<SearchRegular />}
                            onClick={toggleSearchBar}
                            data-testid={"bm-search-button"}
                        />
                    </ActionsContainer>
                </TitleContainer>
                <BreadcrumbWithBack />
            </HeaderContainer>
            <AnimatedSearchBar
                showSearchBar={showSearchBar}
                onChange={debouncedSetSearchTerm}
                name={(lcid && businessModule?.TitleResources?.[lcid]) || businessModule?.Title}
            />
            <TableDisplay
                isLoading={isLoading}
                data={data.rows}
                columns={columns}
                onClickCell={(item) => {
                    navigate(
                        generatePath(Paths.Entity, {
                            businessModuleId: businessModuleId!,
                            entityId: item.ID
                        })
                    )
                }}
                actions={(item: any) => <Actions entityItemId={item.ID} showPinnedRowButtons />}
                getNext={async () => {
                    if (!isFetching && data.nextHref) {
                        const fetchedData = await getEntities(
                            {
                                listId: businessModuleId!,
                                search: searchTerm,
                                nextHref: data.nextHref
                            },
                            true
                        ).unwrap()

                        setData({
                            rows: [...data.rows, ...fetchedData.ListData.Row],
                            nextHref: fetchedData.ListData.NextHref
                        })
                        setAllHrefs([...allHrefs, data.nextHref])
                    }
                }}
                fetchingNext={!!isFetching && !!data.nextHref}
            />
        </PageContainer>
    )
}

export const HeaderTitleText: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Text
            size={400}
            weight="semibold"
            style={{
                marginLeft: "10px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }}
        >
            {children}
        </Text>
    )
}

export const ActionsContainer = styled.div`
    margin-left: auto;
    margin-right: 10px;
    display: flex;
    align-items: center;
`

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
`
