import { useDispatch, useSelector } from "react-redux"
import { ThunkDispatch, combineReducers, configureStore, createAsyncThunk } from "@reduxjs/toolkit"
import { solutionReducer } from "./SolutionReducer"
import { authReducer } from "./AuthReducer"
import { workPointWebApi } from "../service/WorkPointWebApi"
import { messageReducer } from "./MessageReducer"
import { graphWebApi } from "../service/GraphWebApi"
import { businessModuleReducer } from "./BusinessModuleReducer"
import { notificationReducer } from "./NotificationReducer"
import { sharePointWebApi } from "../service/SharePointWebApi"

import storage from "redux-persist/lib/storage"
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import { processReducer } from "./ProcessReducer"

const appReducer = combineReducers({
    auth: authReducer,
    solution: solutionReducer,
    message: messageReducer,
    businessModule: businessModuleReducer,
    notification: notificationReducer,
    process: processReducer,
    [workPointWebApi.reducerPath]: workPointWebApi.reducer,
    [graphWebApi.reducerPath]: graphWebApi.reducer,
    [sharePointWebApi.reducerPath]: sharePointWebApi.reducer
})

const express365Reducer = (state: any, action: any) => {
    if (action.type === "RESET_STATE") {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

const persistConfig = {
    key: "express365",
    storage,
    throttle: 1000,
    whitelist: ["auth", "solution"]
}

const persistedReducer = persistReducer(persistConfig, express365Reducer)

export function setupStore(preloadedState?: any) {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
                }
            }).concat(
                graphWebApi.middleware,
                workPointWebApi.middleware,
                sharePointWebApi.middleware
            ),
        preloadedState // If you have preloadedState, keep it here
    })
}

/**
 * @todo: DUMMY
 */
export const putInstance = createAsyncThunk<any, any, ThunkApiConfig>(
    "install/putInstance",
    async (instance, { rejectWithValue, extra: { fetchExecutor } }) => {
        try {
            /*
            const response = await apiClient.putWP("/Install/Instance", instance)
            if (!response) return rejectWithValue(response)
            return response
            */
        } catch (error) {}
    }
)

export type Express365RootState = ReturnType<typeof express365Reducer>

export type ThunkApiConfig = {
    extra: Express365ExtraArgument
}

export interface Express365ExtraArgument {
    fetchExecutor: { bearerToken: string }
}

export type Express365Dispatch = ThunkDispatch<Express365RootState, Express365ExtraArgument, any>
export type Express365Store = ReturnType<typeof setupStore>

export const useExpress365Dispatch = useDispatch.withTypes<Express365Dispatch>()
export const useExpress365Selector = useSelector.withTypes<Express365RootState>()
