import { useEffect, useState } from "react"
import { Connection, hubConnection } from "signalr-asp-net"
import { useGetProcessInstanceMutation, useGetSignalRModelQuery } from "../service/WorkPointWebApi"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { ProcessNotification } from "../model/ProcessNotification"
import { addProcessNotification } from "../store/ProcessReducer"
import { useToast } from "./ToastProvider"
import { useIntl } from "react-intl"

let connection: Connection | undefined = undefined
const hubsToConnectTo = ["ProgressHub"]

export const useNotificationListener = () => {
    const dispatch = useExpress365Dispatch()
    const solution = useExpress365Selector((state) => state.solution.solution)
    const { data: signalRModel } = useGetSignalRModelQuery(undefined, {
        skip: !solution
    })
    const intl = useIntl()

    const [getProcessInstance] = useGetProcessInstanceMutation()

    const [notifications, setNotifications] = useState<ProcessNotification[]>([])
    useEffect(() => {
        dispatch(addProcessNotification(notifications[notifications.length - 1]))
    }, [dispatch, notifications])

    // const displayedProcessInstances = useState<Set<{ id: string; title: string; status: string }>>(
    //     new Set()
    // )[0]

    const { showToast, updateToast } = useToast()

    useEffect(() => {
        if (!signalRModel) {
            return
        }

        if (!connection) {
            const funcs = [
                {
                    functionName: "Invoke",
                    callback: (raw: any) => {
                        let message: { message?: ProcessNotification; raw: string }
                        try {
                            message = { message: JSON.parse(raw), raw }
                            if (message.message) {
                                if (!message.message.instanceId?.startsWith("00000")) {
                                    setNotifications((prevNotifications) => [
                                        ...prevNotifications,
                                        message.message!
                                    ])

                                    if (message.message.status) {
                                        /**
                                         * Trigger cache invalidation check when a process is completed.
                                         */
                                        switch (message.message.status) {
                                            case "Completed": {
                                                window.postMessage({
                                                    type: "express365",
                                                    action: "cache-invalidation-check"
                                                })
                                            }
                                        }
                                    }

                                    /**
                                     * @todo: Enable Signal R for notifications
                                     */

                                    /*
                                    if (
                                        !Array.from(displayedProcessInstances).some(
                                            (inst) => inst.id === message.message.instanceId
                                        )
                                    ) {
                                        getProcessInstance(message.message.instanceId).then(
                                            (response) => {
                                                if ("data" in response) {
                                                    // showToast(
                                                    //     message.message.instanceId,
                                                    //     response.data.title,
                                                    //     message.message.message,
                                                    //     getProcessInstanceStatusText(
                                                    //         intl,
                                                    //         response.data.status ?? ""
                                                    //     )
                                                    // )
                                                    displayedProcessInstances.add({
                                                        id: message.message.instanceId,
                                                        title: response.data.title,
                                                        status: getProcessInstanceStatusText(
                                                            intl,
                                                            response.data.status ?? ""
                                                        )
                                                    })
                                                }
                                            }
                                        )
                                    } else if (
                                        Array.from(displayedProcessInstances).some(
                                            (inst) => inst.id === message.message.instanceId
                                        )
                                    ) {
                                        const processInstance = Array.from(
                                            displayedProcessInstances
                                        ).find((inst) => inst.id === message.message.instanceId)
                                        // updateToast(
                                        //     message.message.instanceId,
                                        //     processInstance!.title,
                                        //     message.message.message,
                                        //     processInstance!.status
                                        // )
                                    }
                                        */
                                }
                            }
                        } catch (e) {
                            message = { raw }
                        }
                    }
                }
            ]

            connection = hubConnection(signalRModel.SignalRUrl, {
                qs: `signalRIdentifier=${signalRModel.SignalRIdentifier}`,
                logging: true
            })
            hubsToConnectTo.forEach((h: string) => {
                const hubProxy = connection!.createHubProxy(h)
                for (let i = 0; i < funcs.length; i++) {
                    hubProxy.on(funcs[i].functionName, funcs[i].callback)
                }
            })

            connection
                .start({ withCredentials: false })
                .done(() => console.log("Now connected"))
                .fail(() => console.log("Connection failed"))

            connection.disconnected(() => console.log("Now disconnected"))
        }
    }, [signalRModel, notifications, showToast, updateToast, getProcessInstance, intl, dispatch])
}
