import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BusinessModule } from "../model/BusinessModule"
import { Solution } from "../model/Solution"
import { Express365RootState } from "../store/store"
import { IMyToolsSettings } from "../model/MyTools"
import { SignalRModel } from "../model/SignalRModel"
import { ProcessInstance } from "../model/ProcessInstance"
import { OutlookMessage } from "../store/MessageReducer"

export const workPointWebApi = createApi({
    reducerPath: "workPointWebApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_WPAPI!,
        // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as Express365RootState).auth?.wpToken
            if (typeof token === "string" && token.length > 0) {
                // include token in req header
                headers.set("authorization", `Bearer ${token}`)
            }

            // Decorate request with WorkPoint365 Header argument here, if present.
            const solutionAbsoluteUrl = (getState() as Express365RootState).solution?.solution?.url
            if (typeof solutionAbsoluteUrl === "string" && solutionAbsoluteUrl.length > 0) {
                headers.set("WorkPoint365Url", solutionAbsoluteUrl)
            }

            return headers
        }
    }),
    tagTypes: ["BusinessModules", "Solutions", "SolutionSpecifics"],
    endpoints: (builder) => ({
        getSolutions: builder.query<Solution[], void>({
            query: () => ({
                url: "api/Install/Solutions",
                method: "GET"
            }),
            providesTags: ["Solutions"],
            keepUnusedDataFor: 3600
        }),
        getBusinessModules: builder.query<BusinessModule[], void>({
            query: () => ({
                url: "api/BusinessModules",
                method: "GET"
            }),
            providesTags: ["SolutionSpecifics"],
            /**
             * We keep the business modules loaded for 1 hour, they are unloaded if the user navigates away from a page that uses them.
             * @warning: Navigating to the 'BusinessModules' route actually unloads the data, so we need to find out why. It does have a selector to the store -> Business Modules though.
             */
            keepUnusedDataFor: 3600
        }),
        getProcesses: builder.query<any[], void>({
            query: () => ({
                url: "api/Process",
                method: "GET"
            }),
            providesTags: ["SolutionSpecifics"],
            keepUnusedDataFor: 3600
        }),
        getProcessInstances: builder.query<ProcessInstance, string>({
            query: (instanceId) => ({
                url: `api/ProcessInstance/${instanceId}`,
                method: "GET"
            })
        }),
        getMyTools: builder.query<IMyToolsSettings[], void>({
            query: () => ({
                url: "api/MyTools",
                method: "GET"
            }),
            providesTags: ["SolutionSpecifics"],
            keepUnusedDataFor: 3600
        }),
        getSignalRModel: builder.query<SignalRModel, void>({
            query: () => ({
                url: "api/SignalR/GetSignalRModel",
                method: "GET"
            })
        }),
        initializeUserProcess: builder.mutation<
            any,
            { processId: string; processArguments: ProcessArguments }
        >({
            query: ({ processId, processArguments }) => ({
                url: `api/Process/${processId}/InitializeUserProcess`,
                method: "POST",
                body: processArguments
            })
        }),
        getMyProcessInstances: builder.query<
            ProcessInstance[],
            { fromISODate: string; nextPage: string }
        >({
            query: (request) => {
                const body = {
                    camlQuery: `<Geq>
                            <FieldRef Name='Created' />
                            <Value Type='DateTime'>${request.fromISODate}</Value>
                        </Geq>`,
                    nextPage: request.nextPage
                }
                return {
                    url: "api/MyProcessInstances",
                    method: "POST",
                    body
                }
            },
            transformResponse: (response: any) => {
                return response.Items.map((item: any) => {
                    return {
                        id: item.wpProcessInstId,
                        title: item.Title,
                        processId: item.wpProcessInstProcessId,
                        status: item.wpProcessInstStatus,
                        // configuration: JSON.parse(item.wpProcessConfiguration),
                        currentStepIndex: item.wpProcessInstStepIndex,
                        // context: JSON.parse(item.wpProcessInstContext),
                        availableTriggers: [],
                        graphData: "",
                        created: item.Created,
                        modified: item.Modified,
                        stepStatus: {},
                        SolutionUrl: item.WebUrl,
                        IgnoreExceptionLogging: false,
                        ttl: 3600
                    }
                })
            }
        }),
        getProcessInstance: builder.mutation<ProcessInstance, string>({
            query: (instanceId) => ({
                url: `api/ProcessInstance/${instanceId}`,
                method: "GET"
            })
        })
    })
})

export const {
    useGetSolutionsQuery,
    useGetBusinessModulesQuery,
    useGetProcessesQuery,
    useGetProcessInstancesQuery,
    useGetMyToolsQuery,
    useGetSignalRModelQuery,
    useInitializeUserProcessMutation,
    useGetMyProcessInstancesQuery,
    useLazyGetMyProcessInstancesQuery,
    useGetProcessInstanceMutation
} = workPointWebApi

interface ProcessArguments {
    businessModuleId: string
    items: any[]
    entityId: number
    parameters: Partial<ProcessParameters>
    messagingId?: string
    Express?: Partial<OutlookMessage>[]
}

interface ProcessParameters {
    BusinessModuleId?: string | null
    BusinessModuleContentType?: string | null
    EntitySiteList?: string | null
    EntitySiteListContentType?: string | null
    EntitySiteListFolder?: string | null
    client: string
    _host_info: string

    [key: string]: string | null | any[] | undefined
}
