import { Button, Spinner, Text } from "@fluentui/react-components"
import React, { useEffect, useState } from "react"
import { BusinessModuleHierarchyType } from "../model/BusinessModule"
import { useGetBusinessModulesQuery } from "../service/WorkPointWebApi"
import { setBusinessModules } from "../store/BusinessModuleReducer"
import { useExpress365Selector, useExpress365Dispatch } from "../store/store"
import styled from "styled-components"
import { ListButton } from "./ListButton"
import { generatePath, useNavigate } from "react-router"
import { Paths } from "../constants/routing"
import { showNotification } from "../store/NotificationReducer"
import { BusinessModuleIcon } from "./BusinessModuleIcon"
import { getNumberLocaleFromStringLocale } from "../constants/LocalizationResource"
import { ChevronDownRegular, ChevronRightRegular } from "@fluentui/react-icons"

export const BusinessModuleHierarchy = () => {
    const solution = useExpress365Selector((state) => state.solution.solution)
    const { data, isFetching, error } = useGetBusinessModulesQuery(undefined, { skip: !solution })
    const { businessModuleHierarchy } = useExpress365Selector((state) => state.businessModule)
    const dispatch = useExpress365Dispatch()
    useEffect(() => {
        if (data) {
            dispatch(setBusinessModules(data))
        }
    }, [data, dispatch])

    useEffect(() => {
        if (error) {
            let errorMessage = ""
            /**
             * @todo: Add exception transformer middleware so that we can capture errors concistently and log them in Application Insights.
             * @see: https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
             */
            if ("message" in error && typeof error?.message === "string")
                errorMessage = error.message
            if (
                "data" in error &&
                "Message" in (error.data as any) &&
                "ExceptionMessage" in (error.data as any)
            )
                errorMessage =
                    (error.data as any).Message + ": " + (error.data as any).ExceptionMessage
            if ("data" in error && "Message" in (error.data as any))
                errorMessage = (error.data as any).Message

            dispatch(showNotification({ message: errorMessage, type: "error" }))
        }
    }, [dispatch, error])

    const lcid = getNumberLocaleFromStringLocale(Office?.context?.displayLanguage)

    return (
        <div style={{ width: "100%" }}>
            {isFetching ? (
                <Spinner size={"tiny"} style={{ margin: "30px 0px" }} />
            ) : (
                <div style={{ marginLeft: "4px" }}>
                    <HierarchyList data={businessModuleHierarchy} indentation={0} lcid={lcid} />
                </div>
            )}
        </div>
    )
}

const HierarchyList = ({
    data,
    indentation,
    lcid
}: {
    data: BusinessModuleHierarchyType[]
    indentation: number
    lcid?: number
}) => {
    return (
        <>
            {data.map((bm, index) => (
                <BMAccordionButton
                    bm={bm}
                    indentation={indentation}
                    lcid={lcid}
                    key={"AccordionButton" + index}
                />
            ))}
        </>
    )
}

const BMAccordionButton = ({
    bm,
    indentation,
    lcid
}: {
    bm: BusinessModuleHierarchyType
    indentation: number
    lcid?: number
}) => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(indentation === 0)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }
    return (
        <React.Fragment key={bm.Id}>
            <DivWithIndentation data-level={indentation}>
                {bm.children.length > 0 ? (
                    <Button
                        style={{ borderWidth: "0px" }}
                        icon={isOpen ? <ChevronDownRegular /> : <ChevronRightRegular />}
                        size="small"
                        onClick={toggleAccordion}
                        data-testid={"bmAccordionButton-" + bm.Id}
                    />
                ) : (
                    <div style={{ width: "30px" }} />
                )}
                <ListButton
                    icon={<BusinessModuleIcon iconUrl={bm.IconUrl} />}
                    title={bm.Title}
                    onClick={() =>
                        navigate(generatePath(Paths.BusinessModules, { businessModuleId: bm.Id }))
                    }
                    data-level={indentation}
                    data-testid={"hierarchy-list-item-" + bm.Id}
                    style={{ paddingLeft: "4px" }}
                >
                    <Text weight={indentation === 0 ? "semibold" : "regular"} wrap={false} truncate>
                        {(lcid && bm.TitleResources?.[lcid]) || bm.Title}
                    </Text>
                </ListButton>
            </DivWithIndentation>
            {isOpen && bm.children.length > 0 && (
                <HierarchyList data={bm.children} indentation={indentation + 1} lcid={lcid} />
            )}
        </React.Fragment>
    )
}

const DivWithIndentation = styled.div<{ ["data-level"]: number }>`
    display: flex;
    align-items: center;
    ${(props) => (props["data-level"] ? `padding-left: ${props["data-level"] * 8 + 12}px;` : "")}
`
