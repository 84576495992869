import React from "react"
import { Link } from "@fluentui/react-components"
//@ts-ignore
import { isObjectLike } from "lodash"
import { PeopleTeam24Regular, ScreenCut20Regular } from "@fluentui/react-icons"
import { getFileTypeIconAsUrl } from "@fluentui/react-file-type-icons"
import { safeLog } from "../util/debug"
import styled from "styled-components"
import { IntlShape, useIntl } from "react-intl"

const plainLinkColor: React.CSSProperties = { color: "#666" }

export type ColumnArgument = {
    item: any
    column: any
    actions: any
}

/**
 * Common field renderes for use with DetailsList Office UI Fabric Component.
 */
const onRenderDateTime = (item: any, column: any): any => {
    let text
    if (item[`${column.StaticName}.`]) {
        text = new Intl.DateTimeFormat(Office?.context?.displayLanguage || "en-GB", {
            timeStyle: "short",
            dateStyle: "short"
        }).format(new Date(item[`${column.StaticName}.`]))
    } else {
        text = item[column.StaticName]
    }
    return <div>{text}</div>
}

const onRenderUserSingleAndMulti = (item: any, column: any): any => {
    const rawValue = item[column.StaticName]

    if (Array.isArray(rawValue) && rawValue.length > 0) {
        const userValues = rawValue
        const valueCount = userValues.length

        return userValues.map((user, userIndex) => (
            <React.Fragment key={userIndex}>
                <Link disabled={true} style={plainLinkColor}>
                    {user.title || user.value}
                </Link>
                {userIndex + 1 < valueCount && ", "}
            </React.Fragment>
        ))
    } else if (
        rawValue &&
        isObjectLike(rawValue) &&
        "email" in rawValue &&
        "id" in rawValue &&
        ("title" in rawValue || "value" in rawValue)
    ) {
        const singleUser = rawValue
        return (
            <Link disabled={true} style={plainLinkColor}>
                {singleUser.title || singleUser.value}
            </Link>
        )
    }

    return <span />
}

const onRenderLookupSingleAndMulti = (item: any, column: any): any => {
    const rawValue = item[column.StaticName]

    if (Array.isArray(rawValue) && rawValue.length > 0) {
        const lookupValues = rawValue as any[]
        const valueCount = lookupValues.length

        return lookupValues.map((lookup, lookupIndex) => (
            <React.Fragment key={lookup.lookupId}>
                <Link disabled={true} style={plainLinkColor}>
                    {lookup.lookupValue}
                </Link>
                {lookupIndex + 1 < valueCount && ", "}
            </React.Fragment>
        ))
    } else if (
        rawValue &&
        isObjectLike(rawValue) &&
        "lookupValue" in rawValue &&
        "lookupId" in rawValue
    ) {
        const singleLookupValue = rawValue as any
        return (
            <Link disabled={true} style={plainLinkColor}>
                {singleLookupValue.lookupValue}
            </Link>
        )
    }

    return <span />
}

const onRenderChoiceSingleAndMulti = (item: any, column: any): any => {
    const rawValue = item[column.StaticName]
    if (Array.isArray(rawValue) && rawValue.length > 0) {
        const valueCount = rawValue.length
        return (rawValue as string[]).map((val, i) => (
            <React.Fragment key={i}>
                <span style={plainLinkColor}>{val}</span>
                {i + 1 < valueCount && ", "}
            </React.Fragment>
        ))
    } else return onRenderText(item, column)
}

const onRenderTaxonomySingleAndMulti = (item: any, column: any): any => {
    const rawValue = item[column.StaticName]

    if (Array.isArray(rawValue) && rawValue.length > 0) {
        const taxonomyValues = rawValue as any[]
        const valueCount = taxonomyValues.length

        return taxonomyValues.map((taxonomy, taxonomyIndex) => (
            <React.Fragment key={taxonomy.TermID}>
                <Link disabled={true} style={plainLinkColor}>
                    {taxonomy.Label}
                </Link>
                {taxonomyIndex + 1 < valueCount && ", "}
            </React.Fragment>
        ))
    } else if (rawValue && isObjectLike(rawValue) && "Label" in rawValue && "TermID" in rawValue) {
        const singleTaxonomyValue = rawValue as any
        return (
            <Link disabled={true} style={plainLinkColor}>
                {singleTaxonomyValue.Label}
            </Link>
        )
    }

    return <span />
}

const onRenderText = (item: any, column: any): any => (
    <span title={item[column.StaticName]}>{item[column.StaticName]}</span>
)

function encodeText(text: string) {
    var n = /[<>&'"\\]/g
    return text ? text.replace(n, getEncodedChar) : ""
}

function getEncodedChar(c: string) {
    var o: { [key: string]: string } = {
        "<": "&lt;",
        ">": "&gt;",
        "&": "&amp;",
        '"': "&quot;",
        "'": "&#39;",
        "\\": "&#92;"
    }
    return o[c]
}

const onRenderNote = (item: any, column: any): any => {
    let html: string = ""

    const value = item[column.StaticName]
    const valueAsEncodedText: string = value ? encodeText(value.toString()) : ""

    if (column.richText) {
        html = value.toString()
    } else {
        html = valueAsEncodedText.replace(/\n/g, "<br>")
    }

    return <div dangerouslySetInnerHTML={{ __html: html }} />
}

const onRenderSiteField = (item: any, column: any): any => {
    const { listId } = column

    if (typeof listId !== "string" || (typeof listId === "string" && listId === "")) {
        return <span />
    }

    const lowercasedField = column.StaticName.toLowerCase()

    if (lowercasedField === "wpsite") {
        const wpSiteValue = item[column.StaticName]

        let siteField = null

        if (typeof wpSiteValue === "string" && wpSiteValue !== "") {
            siteField = (
                // @todo: Handle how site column navigates
                <ScreenCut20Regular data-testid={"field-renderer-icon"} />
            )
        }

        return <div>{siteField}</div>
    }
}

const onRenderAttachments = (item: any, column: any, intl: IntlShape): any => {
    let value = item[column.StaticName]
        ? intl.formatMessage({
              defaultMessage: "Yes",
              id: "yes"
          })
        : intl.formatMessage({
              defaultMessage: "No",
              id: "no"
          })
    return <div>{value}</div>
}

const onRenderUrl = (item: any, column: any): any => (
    <Link href={item[column.StaticName]} target="_blank">
        {item[`${column.StaticName}.desc`]}
    </Link>
)

/**
 * @todo: Improvements:
 * Add link to item or go down folder if 'item.FSObjType === "1"'.
 */
const onRenderIcon = (item: any): any => {
    if (item.FSObjType === "1") {
        return (
            <img
                src={getFileTypeIconAsUrl({ type: 2, size: 32 })}
                alt="FolderIcon"
                data-testid={"field-renderer-folder-icon"}
            />
        )
    } else {
        return (
            <img
                src={getFileTypeIconAsUrl({ extension: item.File_x0020_Type, size: 32 })}
                alt="FileIcon"
            />
        )
    }
}

/**
 *
 * @param item
 * @param column
 */
const onRenderName = (item: any, column: any): any => {
    let fieldValue = item[column.StaticName]

    if (typeof fieldValue !== "string" || fieldValue === "") {
        // Add backup to "FileLeafRef", when we cant fetch the requested field.
        fieldValue = item["FileLeafRef"]
    }

    if (typeof fieldValue !== "string" || fieldValue === "") {
        return <span />
    }

    return <span title={fieldValue}>{fieldValue}</span>
}

// @todo: Get file size
const onRenderFileSize = (item: any): any => {
    const sizeByteValue = item["File_x0020_Size"]

    // const fileSize = getUserFriendlyFileSize(sizeByteValue)

    // return <span>{fileSize}</span>
    return <span>{sizeByteValue}</span>
}

const onRenderTeamsUrl = (item: any): any => {
    const teamsUrl = item["wpteamsurl"]
    const teamsDesc = item["wpteamsurl.desc"]

    if (typeof teamsUrl !== "string" || teamsUrl === "") {
        return <span />
    }

    return (
        <a href={teamsUrl} title={teamsDesc}>
            <PeopleTeam24Regular />
        </a>
    )
}

/**
 * Gives a fieldrenderer appropriate to the field provided.
 *
 * @param {string} fieldType Matching the 'FieldType' property of the field in question, not to be confused with the 'Type' property!
 * @param {string} internalFieldName Internal name of the field.
 */
const getRenderer = (fieldType: string, internalFieldName: string) => {
    const loweredInternalFieldNameString = internalFieldName.toLowerCase()

    // Field name mapping.
    switch (loweredInternalFieldNameString) {
        /**
         * Computed field determining icon of row.
         */
        case "docicon":
            return onRenderIcon

        /**
         * Main 'Name' of the row.
         */
        case "linkfilename":
        case "linkfilenamenomenu":
        case "linktitle":
        case "linktitlenomenu":
        case "title":
        case "fileleafref":
            return onRenderName

        /**
         * These are "Lookup" in type, but should just be treated as regular text.
         */
        case "itemchildcount":
        case "folderchildcount":
            return onRenderText

        case "filesizedisplay":
            return onRenderFileSize

        case "wpsite":
            return onRenderSiteField

        case "wpteamsurl":
            return onRenderTeamsUrl
    }

    const loweredTypeString = fieldType.toLowerCase()

    // Type mapping.
    switch (loweredTypeString) {
        case "attachments":
            return onRenderAttachments

        case "datetime":
            return onRenderDateTime

        case "lookup":
        case "lookupmulti":
            return onRenderLookupSingleAndMulti

        case "user":
        case "usermulti":
            return onRenderUserSingleAndMulti

        case "text":
        case "counter":
        case "calculated":
        case "boolean":
            return onRenderText

        case "note":
            return onRenderNote

        case "url":
            return onRenderUrl

        case "taxonomyfieldtype":
        case "taxonomyfieldtypemulti":
            return onRenderTaxonomySingleAndMulti

        case "choice":
        case "multichoice":
            return onRenderChoiceSingleAndMulti
        default:
            safeLog(
                `The colum type '${fieldType}' for field name '${internalFieldName}' was not supported, defaulting to text.`
            )
            return onRenderText
    }
}

export const FieldRenderer = ({ item, field }: { item: any; field: any }) => {
    const intl = useIntl()
    if (!item || !field) return null
    return (
        <FieldRendererContainer>
            {getRenderer(field.FieldType, field.StaticName)(item, field, intl)}
        </FieldRendererContainer>
    )
}

const FieldRendererContainer = styled.div`
    width: max-content;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
