import { Button, Text } from "@fluentui/react-components"
import { AlertRegular, MailRegular, AttachRegular } from "@fluentui/react-icons"
import styled from "styled-components"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { OutlookMessage } from "../store/MessageReducer"
import { expireWpToken, expireWpTokenSoon, wpApiReadySelector } from "../store/AuthReducer"
import { BusinessModuleHierarchy } from "../components/BusinessModuleHierarchy"
import { ListButton } from "../components/ListButton"
import { showNotification } from "../store/NotificationReducer"
import { SolutionRow } from "../components/SolutionRow"
import { useState, useEffect } from "react"

export function Home() {
    const wpApiReady = useExpress365Selector(wpApiReadySelector)
    const dispatch = useExpress365Dispatch()
    const { selectedMessages } = useExpress365Selector((state) => state.message)
    const { wpTokenExpiry } = useExpress365Selector((state) => state.auth)

    /* @todo: MBE Remove before release */
    const [keyPressCount, setKeyPressCount] = useState(0)
    const [showContent, setShowContent] = useState(false)
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "a" || event.key === "A") {
                setKeyPressCount((prevCount: number) => prevCount + 1)
            } else if (event.key === "s" || event.key === "S") {
                setKeyPressCount(0)
            }
        }

        window.addEventListener("keydown", handleKeyPress)

        return () => {
            window.removeEventListener("keydown", handleKeyPress)
        }
    }, [])
    useEffect(() => {
        if (keyPressCount === 3) {
            setShowContent(true)
        } else if (keyPressCount === 0) {
            setShowContent(false)
        }
    }, [keyPressCount])

    return (
        <PageContainer>
            {wpApiReady && (
                <>
                    <SolutionRow />
                    <BusinessModuleHierarchy />
                </>
            )}

            {/* @todo: MBE Remove before release */}
            {showContent && (
                <>
                    <Text weight="bold" style={{ paddingLeft: "10px", paddingTop: "20px" }}>
                        Debug tools
                    </Text>
                    <ListButton
                        icon={<AlertRegular />}
                        style={{ borderWidth: "0px", justifyContent: "left" }}
                        onClick={() => dispatch(expireWpToken())}
                    >
                        Clear wp token
                    </ListButton>
                    <ListButton
                        icon={<AlertRegular />}
                        style={{ borderWidth: "0px", justifyContent: "left" }}
                        onClick={() => dispatch(expireWpTokenSoon())}
                    >
                        Expire wp token soon (5'ish minutes)
                    </ListButton>
                    <ListButton
                        icon={<AlertRegular />}
                        style={{ borderWidth: "0px", justifyContent: "left" }}
                        onClick={() => {
                            dispatch(
                                showNotification({
                                    message: `wpToken expires in ${(wpTokenExpiry! - new Date().getTime()) / 1000 / 60} minutes.`,
                                    intent: "warning"
                                })
                            )
                        }}
                    >
                        Check expiration times
                    </ListButton>
                    <ListButton
                        icon={<AlertRegular />}
                        style={{ borderWidth: "0px", justifyContent: "left" }}
                        onClick={() => {
                            dispatch({ type: "RESET_STATE" })
                        }}
                    >
                        Reset API state
                    </ListButton>

                    {selectedMessages &&
                        selectedMessages.length > 0 &&
                        selectedMessages.map((message: OutlookMessage) => (
                            <ListButton
                                key={message.itemId}
                                onClick={() => {
                                    dispatch(
                                        showNotification({
                                            message: message.subject,
                                            intent: "warning"
                                        })
                                    )
                                }}
                                icon={<MailRegular />}
                                style={{
                                    borderWidth: "0px",
                                    justifyContent: "left"
                                }}
                            >
                                {message.subject}{" "}
                                {message.hasAttachments?.toString() === "true" && <AttachRegular />}
                            </ListButton>
                        ))}
                </>
            )}
        </PageContainer>
    )
}

export const IconButton = styled(Button)`
    border-width: 0px;
`

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    height: calc(100vh - 50px);
`
