import { Avatar, createTableColumn, TableColumnDefinition, Text } from "@fluentui/react-components"
import { useEffect, useState } from "react"
import {
    useGetEntitiesQuery,
    useGetListDataQuery,
    useGetListsAndLibrariesQuery,
    useLazyGetListDataQuery
} from "../service/SharePointWebApi"
import { generatePath, useNavigate, useParams } from "react-router"
import { SearchRegular } from "@fluentui/react-icons"
import { BreadcrumbWithBack } from "../components/BreadcrumbWithBack"
import { IconButton, PageContainer } from "./Home"
import { HeaderContainer, TitleContainer } from "./SolutionsPage"
import { FieldRenderer } from "../components/FieldRenderer"
import { Paths } from "../constants/routing"
import { TableDisplay } from "../components/TableDisplay"
import { Actions } from "../components/Actions"
import { ActionsContainer, HeaderTitleText } from "./BusinessModulePage"
import { useDebouncedCallback } from "use-debounce"
import { AnimatedSearchBar } from "../components/AnimatedSearchBar"
import { ListTemplateType } from "../constants/ListTemplateType"
import { useExpress365Selector } from "../store/store"
import { getOfficeClientProgramOpenURIScheme } from "../util/office-utils"
import { useContextDeltaCheck } from "../hooks/deltaChecks"

export const EntityListPage = () => {
    const columnsDef: TableColumnDefinition<any>[] = []
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSetSearchTerm = useDebouncedCallback(setSearchTerm, 400)

    const [allHrefs, setAllHrefs] = useState<string[]>([])

    const { solution } = useExpress365Selector((state) => state.solution)

    const { listId, viewId, folderPath, ...params } = useParams() as {
        listId: string
        viewId?: string
        folderPath?: string
    }

    const { businessModuleId, entityId } = params as { businessModuleId: string; entityId: string }

    const [listData, setListData] = useState<{ rows: any[]; nextHref: string; fields: any[] }>({
        rows: [],
        nextHref: "",
        fields: []
    })

    const { entity, isEntityFetching } = useGetEntitiesQuery(
        { listId: businessModuleId },
        {
            selectFromResult: ({ data, isFetching }) => ({
                entity: data?.ListData.Row.find((item: any) => item.ID === entityId),
                isEntityFetching: isFetching
            })
        }
    )

    const { listOrLibrary, isListFetching } = useGetListsAndLibrariesQuery(entity?.wpSite!, {
        skip: !entity,
        selectFromResult: ({ data, isFetching }) => ({
            listOrLibrary: data?.find((item) => item.Id === listId),
            isListFetching: isFetching
        })
    })

    const [showSearchBar, setShowSearchBar] = useState(false)
    const toggleSearchBar = () => {
        setShowSearchBar((prev) => !prev)
    }

    const [columns, setColumns] = useState(columnsDef)

    let titleInternalName: string
    switch ((listOrLibrary?.BaseTemplate ?? 0) as ListTemplateType) {
        case ListTemplateType.DocumentLibrary:
            titleInternalName = "FileLeafRef"
            break
        default:
            titleInternalName = "Title"
            break
    }

    const { data, isLoading } = useGetListDataQuery(
        {
            listId: listId,
            webUrl: entity?.wpSite,
            viewId,
            folderServerRelativeUrl: folderPath,
            filterValue: searchTerm,
            filterField: titleInternalName
        },
        { skip: !listId || !entity }
    )

    const [getListData, { isFetching }] = useLazyGetListDataQuery()

    useEffect(() => {
        if (data) {
            if (allHrefs.length > 0) {
                const promises = []
                for (const href of allHrefs) {
                    const promise = getListData(
                        {
                            listId: listId!,
                            webUrl: entity?.wpSite,
                            viewId,
                            folderServerRelativeUrl: folderPath,
                            filterValue: searchTerm,
                            filterField: titleInternalName,
                            nextHref: href
                        },
                        true
                    ).unwrap()

                    promises.push(promise)
                }
                Promise.all(promises).then((results) => {
                    const rows = results.flatMap((result) => result.ListData.Row)
                    setListData({
                        rows: [...data.ListData.Row, ...rows],
                        nextHref: results[results.length - 1].ListData.NextHref,
                        fields: results[0].ListSchema.Field
                    })
                })
            } else {
                setListData({
                    rows: data.ListData.Row,
                    nextHref: data.ListData.NextHref,
                    fields: data.ListSchema.Field
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (listData?.fields && listData.fields.length > 0)
            setColumns(
                listData.fields.map((field: any) =>
                    createTableColumn<any>({
                        columnId: field.Name,
                        renderHeaderCell: () => (
                            <Text
                                weight="semibold"
                                style={{
                                    whiteSpace: "nowrap"
                                }}
                                title={field.DisplayName}
                            >
                                {field.DisplayName}
                            </Text>
                        ),
                        renderCell: (item) => <FieldRenderer item={item} field={field} />
                    })
                )
            )
    }, [listData?.fields, setColumns])

    useContextDeltaCheck(
        { listId: businessModuleId, itemId: entityId },
        { listId, siteRelativeUrl: entity?.wpSite },
        30000
    )

    return (
        <PageContainer>
            <HeaderContainer>
                <TitleContainer>
                    {entity && (
                        <>
                            <Avatar
                                color="colorful"
                                name={entity.Title}
                                style={{ borderRadius: "0px" }}
                            />
                            <HeaderTitleText>{entity.Title}</HeaderTitleText>
                        </>
                    )}
                    <ActionsContainer>
                        <Actions />
                        <IconButton icon={<SearchRegular />} onClick={toggleSearchBar} />
                    </ActionsContainer>
                </TitleContainer>
                <BreadcrumbWithBack />
            </HeaderContainer>
            <AnimatedSearchBar
                showSearchBar={showSearchBar}
                onChange={debouncedSetSearchTerm}
                name={listOrLibrary?.Title}
            />
            <TableDisplay
                isLoading={isEntityFetching || isListFetching || isLoading}
                data={listData.rows}
                columns={columns}
                onClickCell={(item) => {
                    if (
                        item.FSObjType === "1" &&
                        typeof item.FileRef === "string" &&
                        item.FileRef.length !== 0
                    ) {
                        navigate(
                            generatePath(Paths.EntityListViewFolder, {
                                businessModuleId,
                                entityId,
                                listId,
                                viewId: viewId!,
                                folderPath: encodeURIComponent(item.FileRef)
                            })
                        )
                    } else if (
                        typeof item.FileRef === "string" &&
                        item.FileRef !== "" &&
                        solution?.url &&
                        solution.url.trim() !== ""
                    ) {
                        openFile(item.FileRef, solution?.url)
                    }
                }}
                actions={(item: any) => (
                    <Actions
                        /**
                         * If the item is a folder, then feed the FileRef as the folder path.
                         */
                        libraryOrListFolderPath={
                            item.FSObjType === "1" &&
                            typeof item.FileRef === "string" &&
                            item.FileRef.trim() !== "" &&
                            item.FileRef
                        }
                        showPinnedRowButtons
                    />
                )}
                getNext={async () => {
                    if (!isFetching && listData.nextHref) {
                        const fetchedData = await getListData(
                            {
                                listId: listId!,
                                webUrl: entity?.wpSite,
                                viewId,
                                folderServerRelativeUrl: folderPath,
                                filterValue: searchTerm,
                                filterField: titleInternalName,
                                nextHref: listData.nextHref
                            },
                            true
                        ).unwrap()

                        setListData({
                            rows: [...listData.rows, ...fetchedData.ListData.Row],
                            nextHref: fetchedData.ListData.NextHref,
                            fields: listData.fields
                        })
                        setAllHrefs([...allHrefs, listData.nextHref])
                    }
                }}
                fetchingNext={!!isFetching && !!listData.nextHref}
            />
        </PageContainer>
    )
}

const openFile = (fileUrl: string, solutionUrl: string) => {
    const officeOpenUriScheme = getOfficeClientProgramOpenURIScheme(fileUrl)

    if (officeOpenUriScheme) {
        window.open(`${officeOpenUriScheme}${new URL(fileUrl, solutionUrl)}`)
    } else {
        window.open(new URL(fileUrl, solutionUrl).toString())
    }
}
