export enum Paths {
    Home = "/*",
    HomeDirect = "/",
    Solutions = "/solutionslist",
    Process = "/process",
    Messages = "/messages",
    BusinessModules = "/businessmodules/:businessModuleId",
    BusinessModulesViews = "/businessmodules/:businessModuleId/views/:viewId",
    Entity = "/businessmodules/:businessModuleId/entity/:entityId",
    EntityListView = "/businessmodules/:businessModuleId/entity/:entityId/list/:listId/view/:viewId",
    EntityListViewFolder = "/businessmodules/:businessModuleId/entity/:entityId/list/:listId/view/:viewId/folder/:folderPath"
}

export enum WorkPointApiPaths {
    BusinessModules = "/api/BusinessModules",
    MyTools = "/api/MyTools",
    MyProcessInstances = "/api/MyProcessInstances",
    Solutions = "/api/Install/Solutions"
}
