import {
    PhoneKey24Regular,
    LinkAdd24Regular,
    LineHorizontal4Search20Regular,
    DesktopFlow24Regular,
    Folder24Regular,
    Code24Regular,
    BuildingMultiple24Regular,
    Person24Regular,
    PersonSwap24Regular,
    PersonNote24Regular,
    Dismiss24Regular,
    CopyArrowRight24Regular,
    DocumentBriefcase24Regular,
    DocumentBulletList24Regular,
    DocumentEdit24Regular,
    Mail24Regular,
    MailArrowDown20Regular,
    MailArrowUp24Regular,
    BriefcasePerson24Regular,
    CalendarAdd24Regular,
    Book24Regular,
    ArrowTrendingLines24Regular,
    PersonFeedback24Regular,
    ChartMultiple24Regular,
    AppsListDetail24Regular,
    AppsList24Regular,
    MailAttach24Regular,
    FolderArrowUp24Regular,
    TextBulletListSquareWarning24Regular,
    NoteEdit24Regular,
    Organization24Regular,
    PreviewLink24Regular,
    Print24Regular,
    Layer24Regular,
    FolderLightning24Regular,
    CalendarDataBar24Regular,
    LinkMultiple24Regular,
    People24Regular,
    PersonArrowLeft24Regular,
    PersonArrowRight24Regular,
    LinkDismiss24Regular,
    DataBarVertical24Regular,
    DocumentTable24Regular,
    DataTrending24Regular,
    DataArea24Regular,
    ScreenSearch24Regular,
    CheckmarkSquare24Regular,
    WindowDevTools24Regular,
    Options24Regular,
    DocumentPerson20Regular,
    DocumentOnePageLink24Regular,
    Flow24Regular,
    ProductionCheckmark24Regular,
    BookTemplate20Regular,
    Warning24Regular,
    ShoppingBag24Regular,
    BuildingPeople24Regular,
    Cube24Regular,
    ArrowRepeatAll24Regular
} from "@fluentui/react-icons"

const iconMaps = {
    PhoneKey24Regular,
    LinkAdd24Regular,
    LineHorizontal4Search20Regular,
    DesktopFlow24Regular,
    Folder24Regular,
    Code24Regular,
    BuildingMultiple24Regular,
    Person24Regular,
    PersonSwap24Regular,
    PersonNote24Regular,
    Dismiss24Regular,
    CopyArrowRight24Regular,
    DocumentBriefcase24Regular,
    DocumentBulletList24Regular,
    DocumentEdit24Regular,
    Mail24Regular,
    MailArrowDown20Regular,
    MailArrowUp24Regular,
    BriefcasePerson24Regular,
    CalendarAdd24Regular,
    Book24Regular,
    ArrowTrendingLines24Regular,
    PersonFeedback24Regular,
    ChartMultiple24Regular,
    AppsListDetail24Regular,
    AppsList24Regular,
    MailAttach24Regular,
    FolderArrowUp24Regular,
    TextBulletListSquareWarning24Regular,
    NoteEdit24Regular,
    Organization24Regular,
    PreviewLink24Regular,
    Print24Regular,
    Layer24Regular,
    FolderLightning24Regular,
    CalendarDataBar24Regular,
    LinkMultiple24Regular,
    People24Regular,
    PersonArrowLeft24Regular,
    PersonArrowRight24Regular,
    LinkDismiss24Regular,
    DataBarVertical24Regular,
    DocumentTable24Regular,
    DataTrending24Regular,
    DataArea24Regular,
    ScreenSearch24Regular,
    CheckmarkSquare24Regular,
    WindowDevTools24Regular,
    Options24Regular,
    DocumentPerson20Regular,
    DocumentOnePageLink24Regular,
    Flow24Regular,
    ProductionCheckmark24Regular,
    BookTemplate20Regular,
    Warning24Regular,
    ShoppingBag24Regular,
    BuildingPeople24Regular,
    ArrowRepeatAll24Regular
}

const oldToNewIcon = {
    Addkeywords: "PhoneKey24Regular",
    Addlinks: "LinkAdd24Regular",
    Audit: "LineHorizontal4Search20Regular",
    BusinessProcesses: "DesktopFlow24Regular",
    Case: "Folder24Regular",
    CodeSign: "Code24Regular",
    Company: "BuildingMultiple24Regular",
    Complaints: "TextBulletListSquareWarning24Regular",
    Contact: "Person24Regular",
    Contact_move: "PersonSwap24Regular",
    Cv: "PersonNote24Regular",
    Delete: "Dismiss24Regular",
    Document_copytoentity: "CopyArrowRight24Regular",
    Document_copytotemplib: "DocumentBriefcase24Regular",
    Doc: "DocumentBulletList24Regular",
    Edit: "DocumentEdit24Regular",
    Email: "Mail24Regular",
    Email_incoming: "MailArrowDown20Regular",
    Email_outgoing: "MailArrowUp24Regular",
    Employee: "BriefcasePerson24Regular",
    Event: "CalendarAdd24Regular",
    Handbook: "Book24Regular",
    Hierarchies: "Organization24Regular",
    Improvements: "ArrowTrendingLines24Regular",
    Interview: "PersonFeedback24Regular",
    KPI: "ChartMultiple24Regular",
    List: "AppsListDetail24Regular",
    Log: "AppsList24Regular",
    Mailmerge: "MailAttach24Regular",
    NavigateUp: "FolderArrowUp24Regular",
    Nonconformities: "TextBulletListSquareWarning24Regular",
    Note: "NoteEdit24Regular",
    Organization: "Organization24Regular",
    Preview: "PreviewLink24Regular",
    Printer: "Print24Regular",
    Process: "Layer24Regular",
    Project: "FolderLightning24Regular",
    Quickdata: "CalendarDataBar24Regular",
    Refresh: "ArrowRepeatAll24Regular",
    Relationlink: "LinkMultiple24Regular",
    Relation: "People24Regular",
    Relation_incoming: "PersonArrowLeft24Regular",
    Relation_outgoing: "PersonArrowRight24Regular",
    Removelinks: "LinkDismiss24Regular",
    Report1: "DataBarVertical24Regular",
    Report2: "DocumentTable24Regular",
    Report3: "DataTrending24Regular",
    Report4: "DataArea24Regular",
    Sales: "ShoppingBag24Regular",
    Search: "ScreenSearch24Regular",
    Select: "CheckmarkSquare24Regular",
    Service: "WindowDevTools24Regular",
    Settings: "Options24Regular",
    Shareddocs: "DocumentPerson20Regular",
    Site: "DocumentOnePageLink24Regular",
    Stage: "Flow24Regular",
    Supplier: "BuildingPeople24Regular",
    Task: "ProductionCheckmark24Regular",
    "Template-library": "BookTemplate20Regular",
    Warning: "Warning24Regular"
}

export const BusinessModuleIcon = ({ iconUrl }: { iconUrl: string }) => {
    const DefaultIcon = <Cube24Regular data-testid="business-module-icon-default" />
    const iconName = /Color\/(.*)_32\.png/i.exec(iconUrl)?.[1]
    if (iconName) {
        const Icon =
            iconMaps[oldToNewIcon[iconName as keyof typeof oldToNewIcon] as keyof typeof iconMaps]
        return Icon ? <Icon data-testid="business-module-icon" /> : DefaultIcon
    } else {
        return DefaultIcon
    }
}
