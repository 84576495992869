import { createSlice } from "@reduxjs/toolkit"

export interface NotificationState {
    notifications: Notification[]
}

export interface Notification {
    message: string
    intent: "info" | "warning" | "error" | "success"
    timestamp: Date
    dismissed: boolean
    id: number
}

const initialState: NotificationState = {
    notifications: []
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        showNotification: (state, action) => {
            const decoratedNotification: Notification = {
                ...action.payload,
                timestamp: new Date().getTime(),
                dismissed: false,
                id: state.notifications.length + 1
            }
            switch (decoratedNotification.intent) {
                case "info":
                    console.log("INFO", decoratedNotification.message)
                    break
                case "warning":
                    console.warn("WARNING", decoratedNotification.message)
                    break
                case "error":
                    console.error("ERROR", decoratedNotification.message)
                    break
                case "success":
                    console.log("SUCCESS", decoratedNotification.message)
                    break
            }

            state.notifications.push(decoratedNotification)
        },
        resetNotification: (state, action) => {
            const selectedNotification = state.notifications.find(
                (notification) => notification.id === action.payload
            )
            if (selectedNotification) {
                selectedNotification.dismissed = true
            }
        }
    }
})

export const { showNotification, resetNotification } = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer
