import { Tab, TabList, tokens } from "@fluentui/react-components"
import { FlowRegular } from "@fluentui/react-icons"
import styled from "styled-components"
import { FlexRow } from "./ProcessDrawer"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { toggleProcessDrawer } from "../store/ProcessReducer"

export const NotificationBar = () => {
    const dispatch = useExpress365Dispatch()
    const { processDrawerOpen } = useExpress365Selector((state) => state.process)

    return (
        <NotificationBarContainer
            open={processDrawerOpen}
            onClick={() => dispatch(toggleProcessDrawer())}
        >
            <FlexRow>
                <TabList selectedValue={""}>
                    <Tab icon={<FlowRegular fontWeight={900} />} value={"processes"} />
                    {/* <Tab icon={<AlertRegular />} value={"notifications"} /> */}
                </TabList>
            </FlexRow>
        </NotificationBarContainer>
    )
}

const NotificationBarContainer = styled.div<{ open?: boolean }>`
    position: absolute;
    height: ${(props) => (props.open ? "70%" : "44px")};
    opacity: ${(props) => (props.open ? "0" : "1")};
    bottom: 0px;
    width: 100%;
    border-top: 1px solid ${tokens.colorNeutralStroke1};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: all ${(props) => (props.open ? "40ms ease-out" : "0ms")};
    box-shadow: ${tokens.colorNeutralStroke1} 0px 0px 2px 2px;

    &:hover {
        cursor: pointer;
    }
`
