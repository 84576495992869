import { FC, useCallback } from "react"
import {
    Spinner,
    Text,
    Toast,
    ToastBody,
    Toaster,
    ToastTitle,
    ToastTrigger,
    tokens,
    useToastController
} from "@fluentui/react-components"
import { DismissRegular } from "@fluentui/react-icons"
import { IconButton } from "../pages/Home"

const toasterId = "toasterId"

export const useToast = () => {
    const { dispatchToast, updateToast } = useToastController(toasterId)

    const showToast = useCallback(
        (toastId: string, title: string, message: string, status: string) => {
            dispatchToast(<ProcessToast title={title} message={message} status={status} />, {
                toastId,
                timeout: 10000,
                pauseOnHover: true,
                position: "bottom",
                root: { style: { width: "100%" } }
            })
        },
        [dispatchToast]
    )

    const toastUpdater = useCallback(
        (toastId: string, title: string, message: string, status: string = "Running") => {
            updateToast({
                content: <ProcessToast title={title} message={message} status={status} />,
                toastId
            })
        },
        [updateToast]
    )

    const showSimpleToast = useCallback(
        (title: string, message: string) => {
            dispatchToast(
                <Toast>
                    <ToastTitle
                        media={<Spinner size="extra-tiny" />}
                        action={
                            <ToastTrigger>
                                <IconButton icon={<DismissRegular />} size="small" />
                            </ToastTrigger>
                        }
                    >
                        {title}
                    </ToastTitle>
                    <ToastBody>
                        <Text
                            style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}
                        >
                            {message}
                        </Text>
                    </ToastBody>
                </Toast>
            )
        },
        [dispatchToast]
    )

    return { showToast, updateToast: toastUpdater, showSimpleToast }
}

export const ToastProvider = () => {
    return <Toaster toasterId={toasterId} limit={3} />
}

const ProcessToast: FC<{ title: string; message: string; status: string }> = ({
    title,
    message,
    status
}) => {
    return (
        <Toast>
            <ToastTitle
                media={<Spinner size="extra-tiny" />}
                action={
                    <ToastTrigger>
                        <IconButton icon={<DismissRegular />} size="small" />
                    </ToastTrigger>
                }
            >
                {title}
            </ToastTitle>
            <ToastBody>
                <Text
                    style={{
                        fontSize: "12px",
                        color: tokens.colorNeutralForeground4
                    }}
                >
                    {status}
                </Text>
                <Text
                    style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >
                    {message}
                </Text>
            </ToastBody>
        </Toast>
    )
}
