import { Spinner, Text, tokens } from "@fluentui/react-components"
import { CheckmarkCircle20Filled, ErrorCircle20Filled, Info20Filled } from "@fluentui/react-icons"
import { IntlShape, useIntl } from "react-intl"
import styled from "styled-components"
import { ProcessInstance } from "../model/ProcessInstance"

export const NotificationCard = ({
    name,
    message,
    processInstance
}: {
    name: string
    message?: string
    processInstance: ProcessInstance
}) => {
    const intl = useIntl()

    let time
    if (processInstance?.created) {
        if (new Date(processInstance.created) > new Date(new Date().setUTCHours(0, 0, 0, 0))) {
            time = new Intl.DateTimeFormat("da-DK", { timeStyle: "short" }).format(
                new Date(processInstance.created)
            )
        } else {
            time = new Intl.DateTimeFormat("da-DK", { day: "2-digit", month: "short" }).format(
                new Date(processInstance.created)
            )
        }
    }
    return (
        <NotificationCardContainer data-testid={name}>
            <StatusContainer>
                {processInstance?.status === "Failed" ? (
                    <ErrorCircle20Filled color={tokens.colorStatusDangerForeground1} />
                ) : processInstance?.status === "Succeeded" ? (
                    <CheckmarkCircle20Filled color={tokens.colorStatusSuccessForeground1} />
                ) : processInstance?.status === "AwaitingUserInput" ? (
                    <Info20Filled />
                ) : (
                    <Spinner size="extra-tiny" />
                )}
            </StatusContainer>
            <Text
                weight={"bold"}
                style={{
                    gridRow: "span 1",
                    gridColumn: "2 / 3",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
            >
                {processInstance?.title ?? "Process Name"}
            </Text>
            <Text
                title={processInstance?.created}
                style={{ justifySelf: "right", marginRight: "8px" }}
            >
                {time}
            </Text>
            <Text
                style={{
                    gridRow: "span 1",
                    gridColumn: "2 / 4",
                    fontSize: "12px",
                    color: tokens.colorNeutralForeground4
                }}
            >
                {getProcessInstanceStatusText(intl, processInstance?.status ?? "undefined")}
            </Text>
            <Text
                style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    gridRow: "span 1",
                    gridColumn: "2 / 4"
                }}
            >
                {message}
            </Text>
        </NotificationCardContainer>
    )
}

export const getProcessInstanceStatusText = (intl: IntlShape, status: string) => {
    let notificationMessage = "undefined"
    switch (status.toLocaleLowerCase()) {
        case "running":
            notificationMessage = intl.formatMessage({
                defaultMessage: "Running",
                id: "processDrawerRunning"
            })
            break
        case "awaitinguserinput":
            notificationMessage = intl.formatMessage({
                defaultMessage: "Awaiting user input",
                id: "processDrawerAwaitingUserInput"
            })
            break
        case "succeeded":
            notificationMessage = intl.formatMessage({
                defaultMessage: "Process succeeded",
                id: "processDrawerProcessSucceeded"
            })
            break
        case "failed":
            notificationMessage = intl.formatMessage({
                defaultMessage: "Process failed",
                id: "processDrawerProcessFailed"
            })
            break
    }
    return notificationMessage
}

const NotificationCardContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 30px auto auto;
    margin: 10px 0px;
    padding: 10px 0px;
    border-radius: 5px;
    border: 1px solid ${tokens.colorNeutralStroke1};
    background-color: ${tokens.colorNeutralBackground1};
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
`

const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
`
