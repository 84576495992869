import {
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    Dropdown,
    Input,
    Menu,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuItemCheckbox,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Option,
    Spinner,
    Tab,
    TabList,
    Text
} from "@fluentui/react-components"
import {
    AlertBadgeRegular,
    AlertRegular,
    ArrowDownRegular,
    ChevronDownRegular,
    DismissRegular,
    FilterRegular,
    FlowRegular,
    SearchRegular
} from "@fluentui/react-icons"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"
import { ProcessInstance } from "../model/ProcessInstance"
import { IconButton } from "../pages/Home"
import { useLazyGetMyProcessInstancesQuery } from "../service/WorkPointWebApi"
import { toggleProcessDrawer } from "../store/ProcessReducer"
import { useExpress365Dispatch, useExpress365Selector } from "../store/store"
import { NotificationCard } from "./NotificationCard"

const initialCheckedValues = { process: ["allProcesses"], time: ["7days"] }
const initialOrderValues = { sortBy: ["startDate"], order: ["up"] }

enum Status {
    AllStates = "allStates",
    Running = "running",
    AwaitingInput = "awaitingInput",
    Complete = "complete",
    Error = "errors"
}

export const ProcessDrawerButton = () => {
    const { unreadNotifications, processDrawerOpen } = useExpress365Selector(
        (state) => state.process
    )
    const dispatch = useExpress365Dispatch()
    return (
        <IconButton
            icon={
                unreadNotifications && !processDrawerOpen ? <AlertBadgeRegular /> : <AlertRegular />
            }
            onClick={() => {
                dispatch(toggleProcessDrawer())
            }}
        />
    )
}

const ProcessDrawerContent = () => {
    const intl = useIntl()
    const dispatch = useExpress365Dispatch()
    const { processNotifications, processDrawerOpen } = useExpress365Selector(
        (state) => state.process
    )
    const [getMyProcessInstances, { data: processInstanceData, isLoading }] =
        useLazyGetMyProcessInstancesQuery({
            pollingInterval: 20000
        })
    const [processInstances, setProcessInstances] = useState<ProcessInstance[] | undefined>(
        undefined
    )
    const [dropdownValue, setDropdownValue] = useState({
        name: intl.formatMessage({
            defaultMessage: "All States",
            id: "processDrawerAllStates"
        }),
        value: Status.AllStates
    })
    const [searchVisible, setSearchVisible] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [checkedValues, setCheckedValues] = useState<{ process: string[]; time: string[] }>(
        initialCheckedValues
    )
    const [orderValues, setOrderValues] = useState<{ sortBy: string[]; order: string[] }>(
        initialOrderValues
    )
    const options = [
        {
            name: intl.formatMessage({
                defaultMessage: "All States",
                id: "processDrawerAllStates"
            }),
            value: Status.AllStates
        },
        {
            name: intl.formatMessage({
                defaultMessage: "Running",
                id: "processDrawerRunning"
            }),
            value: Status.Running
        },
        {
            name: intl.formatMessage({
                defaultMessage: "Awaiting Input",
                id: "processDrawerAwaitingInput"
            }),
            value: Status.AwaitingInput
        },
        {
            name: intl.formatMessage({
                defaultMessage: "Complete",
                id: "processDrawerComplete"
            }),
            value: Status.Complete
        },
        {
            name: intl.formatMessage({
                defaultMessage: "Errors",
                id: "processDrawerErrors"
            }),
            value: Status.Error
        }
    ]

    const orderNameMap: Record<string, any> = {
        up: {
            startDate: intl.formatMessage({
                defaultMessage: "Newest on top",
                id: "processDrawerNewestOnTop"
            }),
            lastUpdated: intl.formatMessage({
                defaultMessage: "Newest on top",
                id: "processDrawerNewestOnTop"
            }),
            processState: intl.formatMessage({
                defaultMessage: "A to Z",
                id: "processDrawerAToZ"
            }),
            processName: intl.formatMessage({
                defaultMessage: "A to Z",
                id: "processDrawerAToZ"
            })
        },
        down: {
            startDate: intl.formatMessage({
                defaultMessage: "Oldest on top",
                id: "processDrawerOldestOnTop"
            }),
            lastUpdated: intl.formatMessage({
                defaultMessage: "Oldest on top",
                id: "processDrawerOldestOnTop"
            }),
            processState: intl.formatMessage({
                defaultMessage: "Z to A",
                id: "processDrawerZToA"
            }),
            processName: intl.formatMessage({
                defaultMessage: "Z to A",
                id: "processDrawerZToA"
            })
        }
    }

    const fetchProcessInstances = useCallback(() => {
        const calculateDate = (timePeriod: string) => {
            const now = new Date()
            switch (timePeriod) {
                case "hour":
                    return now.setHours(now.getHours() - 1)
                case "24hours":
                    return now.setHours(now.getHours() - 24)
                case "7days":
                    return now.setDate(now.getDate() - 7)
                case "30days":
                    return now.setDate(now.getDate() - 30)
                default:
                    return new Date(0).getTime()
            }
        }

        const date = calculateDate(checkedValues.time[0])

        getMyProcessInstances({
            fromISODate: new Date(date).toISOString(),
            nextPage: ""
        })
    }, [checkedValues.time, getMyProcessInstances])

    useEffect(() => {
        if (processInstanceData && processInstanceData.length > 0)
            setProcessInstances(processInstanceData)
    }, [processInstanceData])

    useEffect(() => {
        fetchProcessInstances()
    }, [fetchProcessInstances])

    useEffect(() => {
        const sortedInstances = sortProcessInstances(processInstances ?? [], orderValues)
        !arraysAreEqual(sortedInstances, processInstances ?? []) &&
            setProcessInstances(sortedInstances)
    }, [orderValues, processInstances])

    const latestNotifications: any[] = getLastOccurrences(processNotifications ?? [])

    const instanceTitles: string[] = processInstances
        ? processInstances.reduce((acc: string[], instance) => {
              if (!acc.includes(instance.title)) {
                  acc.push(instance.title)
              }
              return acc
          }, [])
        : []

    return (
        <StyledDrawer open={processDrawerOpen} position="bottom">
            <StyledDrawerHeader>
                <FlexRow>
                    <TabList defaultSelectedValue="processes">
                        <Tab icon={<FlowRegular fontWeight={900} />} value={"processes"} />
                        {/* <Tab icon={<AlertRegular />} value={"notifications"} /> */}
                    </TabList>
                    <CloseDrawerButton
                        appearance="subtle"
                        aria-label="Close"
                        icon={<ChevronDownRegular />}
                        onClick={() => dispatch(toggleProcessDrawer())}
                    />
                </FlexRow>
            </StyledDrawerHeader>
            <StyledDrawerBody>
                <FlexColumn>
                    <Text weight="semibold" style={{ fontSize: 16 }}>
                        {intl.formatMessage({
                            defaultMessage: "Processes",
                            id: "processDrawerProcesses"
                        })}
                    </Text>
                    <FlexRow>
                        <StyledDropdown
                            appearance={"filled-lighter"}
                            button={<TruncatedSpan>{dropdownValue.name}</TruncatedSpan>}
                            size="small"
                            value={dropdownValue.value}
                            onOptionSelect={(e, data) =>
                                setDropdownValue({
                                    name: data.optionText!,
                                    value: data.optionValue! as Status
                                })
                            }
                        >
                            {options.map((option, index) => (
                                <Option value={option.value} key={index}>
                                    {option.name}
                                </Option>
                            ))}
                        </StyledDropdown>
                        <ButtonsGrid>
                            <Menu checkedValues={orderValues}>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button
                                        appearance="subtle"
                                        icon={<ArrowDownRegular />}
                                        style={{ gridColumn: "2 / 3", gridRow: "1 / 2" }}
                                        data-testid={"processSortButton"}
                                    />
                                </MenuTrigger>

                                <MenuPopover>
                                    <MenuList>
                                        <MenuGroup>
                                            <MenuGroupHeader>
                                                {intl.formatMessage({
                                                    defaultMessage: "Sort By",
                                                    id: "processDrawerSortBy"
                                                })}
                                            </MenuGroupHeader>
                                            <MenuItemCheckbox
                                                name={"sortBy"}
                                                value={"startDate"}
                                                onClick={() =>
                                                    setOrderValues((old) => ({
                                                        ...old,
                                                        sortBy: ["startDate"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Start Date",
                                                    id: "processDrawerStartDate"
                                                })}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"sortBy"}
                                                value={"lastUpdated"}
                                                onClick={() =>
                                                    setOrderValues((old) => ({
                                                        ...old,
                                                        sortBy: ["lastUpdated"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Last Updated",
                                                    id: "processDrawerLastUpdated"
                                                })}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"sortBy"}
                                                value={"processState"}
                                                onClick={() =>
                                                    setOrderValues((old) => ({
                                                        ...old,
                                                        sortBy: ["processState"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Process State",
                                                    id: "processDrawerProcessState"
                                                })}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"sortBy"}
                                                value={"processName"}
                                                onClick={() =>
                                                    setOrderValues((old) => ({
                                                        ...old,
                                                        sortBy: ["processName"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Process Name",
                                                    id: "processDrawerProcessName"
                                                })}
                                            </MenuItemCheckbox>
                                        </MenuGroup>
                                        <MenuDivider />
                                        <MenuGroup>
                                            <MenuGroupHeader>
                                                {intl.formatMessage({
                                                    defaultMessage: "Sort order",
                                                    id: "processDrawerSortOrder"
                                                })}
                                            </MenuGroupHeader>
                                            <MenuItemCheckbox
                                                name={"order"}
                                                value={"up"}
                                                onClick={() =>
                                                    setOrderValues((old) => ({
                                                        ...old,
                                                        order: ["up"]
                                                    }))
                                                }
                                            >
                                                {orderNameMap.up[orderValues.sortBy[0]]}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"order"}
                                                value={"down"}
                                                onClick={() =>
                                                    setOrderValues((old) => ({
                                                        ...old,
                                                        order: ["down"]
                                                    }))
                                                }
                                            >
                                                {orderNameMap.down[orderValues.sortBy[0]]}
                                            </MenuItemCheckbox>
                                        </MenuGroup>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            <Menu checkedValues={checkedValues}>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button
                                        appearance="subtle"
                                        icon={<FilterRegular />}
                                        style={{ gridColumn: "3 / 4", gridRow: "1 / 2" }}
                                    />
                                </MenuTrigger>

                                <MenuPopover>
                                    <MenuList>
                                        <MenuGroup>
                                            <MenuGroupHeader>
                                                {intl.formatMessage({
                                                    defaultMessage: "Process",
                                                    id: "processDrawerProcess"
                                                })}
                                            </MenuGroupHeader>
                                            <MenuItemCheckbox
                                                name={"process"}
                                                value={"allProcesses"}
                                                onClick={() =>
                                                    setCheckedValues((old) => ({
                                                        ...old,
                                                        process: ["allProcesses"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "All Processes",
                                                    id: "processDrawerAllProcesses"
                                                })}
                                            </MenuItemCheckbox>
                                            {instanceTitles.slice(0, 3).map((title, index) => (
                                                <MenuItemCheckbox
                                                    key={index}
                                                    name={"process"}
                                                    value={title}
                                                    onClick={() =>
                                                        setCheckedValues((old) => ({
                                                            ...old,
                                                            process: [title]
                                                        }))
                                                    }
                                                >
                                                    {title}
                                                </MenuItemCheckbox>
                                            ))}
                                            {instanceTitles.length > 3 && (
                                                <Menu checkedValues={checkedValues}>
                                                    <MenuTrigger disableButtonEnhancement>
                                                        <MenuItem>
                                                            {" "}
                                                            {intl.formatMessage({
                                                                id: "processDrawerMoreProcesses",
                                                                defaultMessage: "More processes..."
                                                            })}
                                                            ;
                                                        </MenuItem>
                                                    </MenuTrigger>
                                                    <MenuPopover>
                                                        <MenuList>
                                                            {instanceTitles
                                                                .slice(3)
                                                                .map((title, index) => (
                                                                    <MenuItemCheckbox
                                                                        key={index}
                                                                        name={"process"}
                                                                        value={title}
                                                                        onClick={() =>
                                                                            setCheckedValues(
                                                                                (old) => ({
                                                                                    ...old,
                                                                                    process: [title]
                                                                                })
                                                                            )
                                                                        }
                                                                    >
                                                                        {title}
                                                                    </MenuItemCheckbox>
                                                                ))}
                                                        </MenuList>
                                                    </MenuPopover>
                                                </Menu>
                                            )}
                                        </MenuGroup>
                                        <MenuDivider />
                                        <MenuGroup>
                                            <MenuGroupHeader>
                                                {intl.formatMessage({
                                                    defaultMessage: "Time range",
                                                    id: "processDrawerTimeRange"
                                                })}
                                            </MenuGroupHeader>
                                            <MenuItemCheckbox
                                                name={"time"}
                                                value={"hour"}
                                                onClick={() =>
                                                    setCheckedValues((old) => ({
                                                        ...old,
                                                        time: ["hour"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Last hour",
                                                    id: "processDrawerLastHour"
                                                })}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"time"}
                                                value={"24hours"}
                                                onClick={() =>
                                                    setCheckedValues((old) => ({
                                                        ...old,
                                                        time: ["24hours"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Last 24 hours",
                                                    id: "processDrawerLast24Hours"
                                                })}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"time"}
                                                value={"7days"}
                                                onClick={() =>
                                                    setCheckedValues((old) => ({
                                                        ...old,
                                                        time: ["7days"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Last 7 days",
                                                    id: "processDrawerLast7Days"
                                                })}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"time"}
                                                value={"30days"}
                                                onClick={() =>
                                                    setCheckedValues((old) => ({
                                                        ...old,
                                                        time: ["30days"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "Last 30 days",
                                                    id: "processDrawerLast30Days"
                                                })}
                                            </MenuItemCheckbox>
                                            <MenuItemCheckbox
                                                name={"time"}
                                                value={"allTime"}
                                                onClick={() =>
                                                    setCheckedValues((old) => ({
                                                        ...old,
                                                        time: ["allTime"]
                                                    }))
                                                }
                                            >
                                                {intl.formatMessage({
                                                    defaultMessage: "All",
                                                    id: "processDrawerAll"
                                                })}
                                            </MenuItemCheckbox>
                                        </MenuGroup>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>

                            <Button
                                appearance="subtle"
                                icon={<SearchRegular />}
                                onClick={() => {
                                    setSearchVisible(true)
                                }}
                                style={{ gridColumn: "4 / 5", gridRow: "1 / 2" }}
                            />
                            <StyledInput
                                contentBefore={<SearchRegular />}
                                contentAfter={
                                    <Button
                                        appearance="subtle"
                                        icon={<DismissRegular />}
                                        onClick={() => {
                                            setSearchTerm("")
                                            setSearchVisible(false)
                                        }}
                                    />
                                }
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                searchVisible={searchVisible}
                            />
                        </ButtonsGrid>
                    </FlexRow>
                </FlexColumn>
                {!processInstances && isLoading && <Spinner />}
                {processInstances && (
                    <ProcessDisplay
                        processInstances={processInstances}
                        checkedValues={checkedValues}
                        filter={dropdownValue.value}
                        searchTerm={searchTerm}
                        isLoading={isLoading}
                        latestNotifications={latestNotifications}
                    />
                )}
            </StyledDrawerBody>
        </StyledDrawer>
    )
}

const ProcessDisplay = ({
    processInstances,
    checkedValues,
    filter,
    searchTerm,
    isLoading,
    latestNotifications
}: {
    processInstances: ProcessInstance[]
    checkedValues: { process: string[]; time: string[] }
    filter: string
    searchTerm: string
    isLoading: boolean
    latestNotifications: any[]
}) => {
    const intl = useIntl()

    if (isLoading) return <Spinner />

    if (processInstances && processInstances.length > 0) {
        const cards = processInstances
            .map((instance: ProcessInstance, index) => {
                const notif = latestNotifications.find(
                    (notifInstance) => notifInstance.instanceId === instance.id
                )
                if (
                    (checkedValues.process[0] === "allProcesses" ||
                        checkedValues.process.includes(instance.title)) &&
                    (filter === "allStates" || statusFilterMap[instance.status] === filter) &&
                    (instance?.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                        notif?.message?.toLowerCase().includes(searchTerm.toLowerCase()))
                ) {
                    return (
                        <NotificationCard
                            key={"NotificationCard" + index}
                            name={"NotificationCard" + index}
                            message={notif?.message}
                            processInstance={instance}
                        />
                    )
                }

                return null
            })
            .filter((card) => card)
        if (cards.length > 0) {
            return <div>{cards}</div>
        } else {
            return (
                <NoProcessesContainer>
                    <Text weight={"semibold"} style={{ textAlign: "center" }}>
                        {intl.formatMessage({
                            defaultMessage: "No processes match the selected filter.",
                            id: "processDrawerNoProcessesInFilter"
                        })}
                    </Text>
                    <Text
                        style={{
                            textAlign: "center"
                        }}
                    >
                        {intl.formatMessage({
                            defaultMessage:
                                "Try changing the status, date, process title or search.",
                            id: "processDrawerNoProcessesInFilterDescription"
                        })}
                    </Text>
                    <img
                        alt="wp-missing-processes"
                        src={"/MissingProcesses.png"}
                        style={{ marginTop: "20px" }}
                    />
                </NoProcessesContainer>
            )
        }
    } else if (processInstances && processInstances.length === 0) {
        return (
            <NoProcessesContainer>
                <Text weight={"semibold"} style={{ textAlign: "center" }}>
                    {intl.formatMessage({
                        defaultMessage: "No processes to display.",
                        id: "processDrawerNoProcessesFound"
                    })}
                </Text>
                <Text
                    style={{
                        textAlign: "center"
                    }}
                >
                    {intl.formatMessage({
                        defaultMessage:
                            "You don't have any recent WorkPoint processes. Start any process to see it here.",
                        id: "processDrawerNoProcessesFoundDescription"
                    })}
                </Text>
                <img
                    alt="wp-missing-processes"
                    src={"/MissingProcesses.png"}
                    style={{ marginTop: "20px" }}
                />
            </NoProcessesContainer>
        )
    }

    return null
}

export const ProcessDrawer = () => {
    const { processDrawerOpen } = useExpress365Selector((state) => state.process)

    return processDrawerOpen ? <ProcessDrawerContent /> : null
}

function getLastOccurrences(arr: any[]): any {
    // Reduce the array to a map of the last occurrences of each instanceId
    const lastOccurrencesMap = arr.reduce((acc: any, obj: any) => {
        if (obj) {
            acc[obj.instanceId] = obj
        }
        return acc
    }, {})

    return Object.values(lastOccurrencesMap)
}

const sortProcessInstances = (
    instances: ProcessInstance[],
    orderValues: { sortBy: string[]; order: string[] }
) => {
    const clonedInstances = [...instances]
    return clonedInstances?.sort((a, b) => {
        if (orderValues.sortBy[0] === "startDate") {
            return orderValues.order[0] === "up"
                ? new Date(b.created).getTime() - new Date(a.created).getTime()
                : new Date(a.created).getTime() - new Date(b.created).getTime()
        } else if (orderValues.sortBy[0] === "lastUpdated") {
            return orderValues.order[0] === "up"
                ? new Date(b.modified).getTime() - new Date(a.modified).getTime()
                : new Date(a.modified).getTime() - new Date(b.modified).getTime()
        } else if (orderValues.sortBy[0] === "processState") {
            return orderValues.order[0] === "up"
                ? a.status.localeCompare(b.status)
                : b.status.localeCompare(a.status)
        } else if (orderValues.sortBy[0] === "processName") {
            return orderValues.order[0] === "up"
                ? a.title.localeCompare(b.title)
                : b.title.localeCompare(a.title)
        }
        return 0
    })
}

const arraysAreEqual = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false
    for (let i = 0; i < arr1.length; i++) {
        if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
            return false
        }
    }
    return true
}

const statusFilterMap: { [key: string]: string } = {
    Running: Status.Running,
    AwaitingUserInput: Status.AwaitingInput,
    Succeeded: Status.Complete,
    Failed: Status.Error
}

const ButtonsGrid = styled.div`
    display: grid;
    margin-left: auto;
    grid-template-columns: 104px auto auto auto;
    grid-template-rows: 34px;
    overflow: hidden;
    padding-right: 8px;
`

const StyledInput = styled(Input)<{ searchVisible: boolean }>`
    grid-column: 1 / 5;
    grid-row: 1 / 2;
    width: 200px;
    transform: ${(props) => (props.searchVisible ? "translateX(0)" : "translateX(120%)")};
    opacity: ${(props) => (props.searchVisible ? "1" : "0")};
    transition:
        transform 0.3s,
        opacity 0.3s;
`

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const TruncatedSpan = styled.span`
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const StyledDrawer = styled(Drawer)`
    height: 70%;
    border-radius: 10px 10px 0px 0px;
`
const StyledDrawerHeader = styled(DrawerHeader)`
    padding: 0px;
    border-bottom: 0px;
`

const CloseDrawerButton = styled(Button)`
    align-self: center;
    margin-left: auto;
`

const StyledDrawerBody = styled(DrawerBody)`
    padding: 15px 15px;
    background-color: #f5f5f5;
    box-shadow: inset 0px 8px 4px -6px #c0c0c0;
`
const StyledDropdown = styled(Dropdown)`
    min-width: 10px;
    max-width: 98px;
    margin-left: -8px;
    background-color: rgba(0, 0, 0, 0);
`

const NoProcessesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 70px);
`
