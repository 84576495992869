import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { ListAndLibrary } from "../model/ListOrLibrary"
import { getToken } from "../auth"

const renderListDataAsStreamResponseTransformer = (response: any) => response?.ListData.Row

const getEntityListsFilter = () => {
    const unwantedBaseTemplates = [336, 433, 500, 815, 110, 112, 116, 124, 170, 119]

    const unwantedEntityTypes = ["SiteAssets", "FormServerTemplates", "Style_x0020_Library"]

    return `Hidden eq false and (${unwantedBaseTemplates.map((bt) => `BaseTemplate ne ${bt}`).join(" and ")} and ${unwantedEntityTypes.map((et) => `EntityTypeName ne '${et}'`).join(" and ")})`
}

const defaultEntityColumns = ["Title", "wpItemLocation", "wpSite", "Modified"]

const baseQuery =
    (baseQueryArgs: any = {}) =>
    async (fetchArgs: any = {}, api: any, extraOptions = {}) => {
        let baseUrl = null
        const { getState } = api
        const state = getState()
        const solutionUrl = state.solution.solution?.url

        /**
         * Lookup for non-root site resources.
         */
        if (fetchArgs.overrideUrl) {
            const overrideUrl = new URL(fetchArgs.overrideUrl, new URL(solutionUrl).origin).href
            baseUrl = `${overrideUrl}/`
        } else {
            baseUrl = `${solutionUrl}/`
        }

        return fetchBaseQuery({
            ...baseQueryArgs,
            baseUrl
        })(fetchArgs, api, extraOptions)
    }

export const sharePointWebApi = createApi({
    tagTypes: ["Entities", "ListItems"],
    reducerPath: "sharePointWebApi",
    baseQuery: baseQuery({
        //@ts-ignore
        prepareHeaders: async (headers: any, { getState }) => {
            const solutionUrl = getState().solution.solution.url

            const token = await getToken([`https://${new URL(solutionUrl).hostname}/.default`])

            if (typeof token === "string" && token.length > 0) {
                // include token in req header
                headers.set("authorization", `Bearer ${token}`)
            } else {
                /**
                 * @todo: Test this.
                 */
                return {
                    status: 400,
                    statusText: "Bad Request",
                    data: "Missing bearer token for SharePoint"
                }
            }

            headers.set("accept", "application/json;odata=verbose")

            return headers
        }
    }),
    endpoints: (builder) => ({
        getEntities: builder.query<
            RenderListDataAsStreamResponse,
            { listId: string; search?: string; nextHref?: string }
        >({
            query: ({ listId, search = "", nextHref }) => {
                let viewXml = null
                const queryParams = new URLSearchParams()

                /**
                 * InplaceSearchQuery is a cross-fields search, so could give false positives.
                 */
                // if (search) queryParams.set("InplaceSearchQuery", `${search}*`)
                // if (viewId) queryParams.set("View", viewId)

                /**
                 * View (Caml) based search on Title.
                 */
                if (search) {
                    viewXml = `<View>
                                    <Query>
                                        <Where>
                                            <Contains>
                                                <FieldRef Name="Title" />
                                                <Value Type="Text">${search}</Value>
                                            </Contains>
                                        </Where>
                                    </Query>
                                    <ViewFields>
                                        ${defaultEntityColumns.map((col) => `<FieldRef Name="${col}" />`).join("")}
                                    </ViewFields>
                                </View>`
                } else {
                    viewXml = `<View>
                                    <ViewFields>
                                        ${defaultEntityColumns.map((col) => `<FieldRef Name="${col}" />`).join("")}
                                    </ViewFields>
                                </View>`
                }

                return {
                    url: `_api/web/lists(guid'${listId}')/RenderListDataAsStream${nextHref ? nextHref : "?" + queryParams.toString()}`,
                    method: "POST",
                    body: {
                        parameters: {
                            AddRequiredFields: false,
                            RenderOptions: 7, // SPRenderListDataOptions.ContextInfo + SPRenderListDataOptions.ListData + SPRenderListDataOptions.ListSchema,
                            // InplaceSearchQuery: search
                            ...{ ...(viewXml && { ViewXml: viewXml }) }
                        }
                    }
                }
            },
            providesTags: (result, error, { listId }) => [{ type: "Entities", id: listId }]
        }),
        getEntity: builder.query<any, { listId: string; id: string }>({
            query: ({ listId, id }) => {
                const viewXml = `
                    <View>
                        <Query>
                            <Where>
                                <Eq>
                                    <FieldRef Name="ID" />
                                    <Value Type="Counter">${id}</Value>
                                </Eq>
                            </Where>
                        </Query>
                        <ViewFields>
                            ${defaultEntityColumns.map((col) => `<FieldRef Name="${col}" />`).join("")}
                        </ViewFields>
                    </View>`

                return {
                    url: `_api/web/lists(guid'${listId}')/RenderListDataAsStream`,
                    method: "POST",
                    body: {
                        parameters: {
                            AddRequiredFields: false,
                            RenderOptions: 7, // SPRenderListDataOptions.ContextInfo + SPRenderListDataOptions.ListData + SPRenderListDataOptions.ListSchema,
                            ViewXml: viewXml
                        }
                    }
                }
            },
            transformResponse: renderListDataAsStreamResponseTransformer
        }),
        getListsAndLibraries: builder.query<ListAndLibrary[], string>({
            query: (webUrl: string) => ({
                url: `/_api/web/Lists?$select=Id,Title,EntityTypeName,RootFolder/ServerRelativeUrl,DefaultView/Id,BaseTemplate&$filter=${getEntityListsFilter()}&$expand=RootFolder/ServerRelativeUrl,DefaultView/Id`,
                overrideUrl: webUrl
            }),
            transformResponse: (response: any) => response?.d?.results
        }),
        getListData: builder.query<
            RenderListDataAsStreamResponse,
            {
                listId: string
                filterValue?: string
                filterField?: string
                viewId?: string
                webUrl?: string
                folderServerRelativeUrl?: string
                nextHref?: string
            }
        >({
            query: ({
                listId,
                filterValue = "",
                filterField = "Title",
                viewId = "",
                webUrl = null,
                folderServerRelativeUrl = null,
                nextHref = null
            }) => {
                let viewXml = null
                const queryParams = new URLSearchParams()

                if (viewId) queryParams.set("View", viewId)

                /**
                 * View (Caml) based search on Title.
                 */
                if (filterValue && filterField) {
                    viewXml = `<View><Query><Where><Contains><FieldRef Name="${filterField}"/><Value Type="Text">${filterValue}</Value></Contains></Where></Query></View>`
                }

                return {
                    url: `_api/web/lists(guid'${listId}')/RenderListDataAsStream${nextHref ? nextHref : "?" + queryParams.toString()}`,
                    method: "POST",
                    body: {
                        parameters: {
                            AddRequiredFields: false,
                            ...(folderServerRelativeUrl && {
                                FolderServerRelativeUrl: folderServerRelativeUrl
                            }),
                            RenderOptions: 7, // SPRenderListDataOptions.ContextInfo + SPRenderListDataOptions.ListData + SPRenderListDataOptions.ListSchema,
                            ...{ ...(viewXml && { ViewXml: viewXml }) }
                        }
                    },
                    overrideUrl: webUrl
                }
            },
            providesTags: (result, error, { listId }) => [{ type: "ListItems", id: listId }],
            transformResponse: (response: any) => {
                const transformedResponse = {
                    ListData: response.ListData,
                    ListSchema: response.ListSchema,
                    viewTitle: response.viewTitle
                }
                return transformedResponse
            }
        })
    })
})

interface RenderListDataAsStreamResponse {
    ListData: {
        Row: any[]
        NextHref: string
    }
    ListSchema: {
        Field: {
            DisplayName: string
            RealFieldName: string
            Type: string
            FieldType: string
            Name: string
        }[]
        ItemCount: string
    }
    viewTitle: string
}

export const {
    useGetEntitiesQuery,
    useLazyGetEntityQuery,
    useGetListsAndLibrariesQuery,
    useLazyGetListsAndLibrariesQuery,
    useGetListDataQuery,
    useLazyGetListDataQuery,
    useLazyGetEntitiesQuery
} = sharePointWebApi
