import { createSlice } from "@reduxjs/toolkit"

export interface ProcessState {
    processNotifications?: any[]
    processDrawerOpen?: boolean
    unreadNotifications?: boolean
}

const initialState: ProcessState = {
    processNotifications: [],
    processDrawerOpen: false,
    unreadNotifications: false
}

const processSlice = createSlice({
    name: "process",
    initialState,
    reducers: {
        addProcessNotification: (state, action) => {
            state.processNotifications?.push(action.payload)
            if (action.payload?.message) state.unreadNotifications = true
        },

        toggleProcessDrawer: (state) => {
            state.processDrawerOpen = !state.processDrawerOpen
            state.unreadNotifications = false
        }
    }
})

export const { toggleProcessDrawer, addProcessNotification } = processSlice.actions

export const processReducer = processSlice.reducer
