import {
    AuthenticationResult,
    Configuration,
    InteractionRequiredAuthError,
    LogLevel,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest
} from "@azure/msal-browser"
import { Express365Dispatch } from "./store/store"
import { setOutlookSSOToken, setWPToken } from "./store/AuthReducer"
import { showNotification } from "./store/NotificationReducer"

const dialogLoginUrl: string =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "") +
    "/login.html"

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_WPAPI_CLIENT_ID!,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: document.location.origin + "/login.html" // Must be registered as "spa" type
    },
    cache: {
        cacheLocation: "localStorage" // needed to avoid "login required" error
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        console.info(message)
                        return
                    case LogLevel.Verbose:
                        console.debug(message)
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                }
            }
        }
    }
}

const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.initialize()

export const obtainOutlookAndWPApiAccessTokens = async (
    dispatch: Express365Dispatch,
    useSSO: boolean
): Promise<any> => {
    if (useSSO) {
        try {
            const accessToken = await Office.auth.getAccessToken({
                allowSignInPrompt: true,
                allowConsentPrompt: true,
                /**
                 * @todo: Add ENV variable for this flag and investigate requirements.
                 * This flag should be set dynamically based on whether we're sideloading the App in development (false) or not (true).
                 */
                forMSGraphAccess: false
            })

            dispatch(setOutlookSSOToken(accessToken))

            try {
                const token = await exchangeSSOTokenForWPAPIToken(accessToken)
                return dispatch(setWPToken(token))
            } catch (error) {
                // @todo: Handle this error, as it is specific to the WorkPoint Web API, after SSO has been successful.
                throw new Error("Could not get token from WorkPoint Web API")
            }
        } catch (error: any) {
            // Handle common errors, and let errors that require MSAL use retry the flow, without 'useSSO'
            console.log("Could not get token from Office.auth.getAccessToken", error)

            /**
             * @see: https://learn.microsoft.com/en-us/office/dev/add-ins/develop/troubleshoot-sso-in-office-add-ins
             * @todo: Rewrite error messages.
             */
            switch (error.code) {
                /**
                 * No one is signed into Office. If the add-in cannot be effectively used when no one is logged into Office.
                 * We use the `allowSignInPrompt: true` option, se we should not see this error.
                 */
                case 13001:
                    dispatch(
                        showNotification({
                            message:
                                "No one is signed into Office. WorkPoint Express 365 is not able to run without a user signed in.",
                            intent: "warning"
                        })
                    )
                    break
                /**
                 * The user aborted the consent prompt. The add-in cannot be effectively used without consent,
                 * so prompt the user with a login dialog containing a consent form.
                 * We are using the `allowConsentPrompt: true` option, so just running the getAccessToken with SSO again should work.
                 */
                case 13002:
                    // @todo: Create a button for the user to trigger the getAccessToken with SSO again.
                    dispatch(
                        showNotification({
                            message:
                                "WorkPoint Express 365 requires user consent. If you want to grant consent, press the login button again.",
                            intent: "info"
                        })
                    )
                    break
                /**
                 * Client Error. This error is only seen in Office on the web. Your code should suggest that the user sign out and then restart the Office browser session.
                 */
                case 13006:
                    dispatch(
                        showNotification({
                            message:
                                "Office on the web is experiencing a problem. Please sign out of Office, close the browser, and then start again.",
                            intent: "warning"
                        })
                    )
                    break
                /**
                 * Only seen in Office on the web.
                 */
                case 13008:
                    dispatch(
                        showNotification({
                            message:
                                "Office is still working on the last operation. When it completes, try this operation again.",
                            intent: "info"
                        })
                    )
                    break
                /**
                 * Only seen in Office on the web.
                 * If this error is returned, the user will have already seen an error explaining this and linking to a page about how to change the zone configuration
                 */
                case 13010:
                    dispatch(
                        showNotification({
                            message:
                                "Follow the instructions to change your browser's zone configuration.",
                            intent: "info"
                        })
                    )
                    break
                /**
                 * Rerun the flow without SSO
                 */
                default:
                    // For all other errors, including 13000, 13003, 13005, 13007, 13012, and 50001, fall back to MSAL sign-in.
                    console.warn("SSO failed. Trying fallback MSAL auth.")
                    return obtainOutlookAndWPApiAccessTokens(dispatch, false)
            }

            return null
        }
    } else {
        const token = await getMSALToken(dispatch)
        return dispatch(setWPToken(token))
    }
}

const loginRequest = {
    scopes: [process.env.REACT_APP_WPAPI_SCOPES!]
}

const getMSALToken = async (dispatch: Express365Dispatch) => {
    return new Promise<string>(async (resolve, reject) => {
        try {
            // Try to get token silently

            const silentTokenResult = await msalInstance.acquireTokenSilent(loginRequest)
            if (silentTokenResult !== null && silentTokenResult.accessToken !== null) {
                return silentTokenResult.accessToken
            }
        } catch (error) {
            console.warn("Error when trying to perform silent login request for MSAL", error)
        }

        /**
         * Office dialog login is available
         */
        if (typeof Office?.context?.ui?.displayDialogAsync === "function") {
            try {
                let dialog: Office.Dialog
                await Office.context.ui.displayDialogAsync(
                    dialogLoginUrl,
                    { height: 50, width: 50 },
                    (result: Office.AsyncResult<Office.Dialog>) => {
                        console.log("Dialog result", result)
                        dialog = result.value
                        if (result.status === Office.AsyncResultStatus.Failed) {
                            console.warn(`${result.error.code} ${result.error.message}`)
                        } else {
                            dialog = result.value
                            dialog.addEventHandler(
                                Office.EventType.DialogMessageReceived,
                                (arg: any) => {
                                    const messageFromDialog = JSON.parse(arg.message)
                                    /**
                                     * @todo: Handle return messages from dialog.
                                     */
                                    console.log("ProcessMessage: ", messageFromDialog)
                                    if (messageFromDialog.status === "success") {
                                        dialog.close()
                                        resolve(messageFromDialog.result)
                                    }
                                }
                            )
                            dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg) => {
                                /**
                                 * @todo: What should we use these for?
                                 */
                                console.log("Dialog event received", arg)
                            })
                        }
                    }
                )
            } catch (error: any) {
                // @todo: Handle possible issues with Office dialog login.
                dispatch(
                    showNotification({
                        message: `MSAL Office Dialog Login error: ${error.message}`,
                        intent: "error"
                    })
                )
            }
        } else {
            /**
             * Office dialog login is not available, so use direct MSAL login, as if we were in a dedicated web app, ie. not running as an Add-in.
             */
            try {
                const response: AuthenticationResult = await msalInstance.loginPopup(loginRequest)
                return resolve(response.accessToken)
            } catch (e: any) {
                // @todo: Handle possible issues with MSAL login.
                dispatch(
                    showNotification({
                        message: `MSAL Direct Login error: ${e.message}`,
                        intent: "error"
                    })
                )
            }
        }
    })
}

const exchangeSSOTokenForWPAPIToken = async (token: string): Promise<string> => {
    return fetch(`${process.env.REACT_APP_WPAPI}/api/token/webapi?client=express`, {
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then((response) =>
        response.json().then((webApiTokenResponse) => webApiTokenResponse.access_token)
    )
}

export const getToken = async (scopes: string[]): Promise<string> => {
    const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts()

    if (!activeAccount && accounts.length === 0) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */

        const authPayload: RedirectRequest = {
            scopes
        }

        await msalInstance.acquireTokenPopup(authPayload)

        return ""
    }

    const silentAuthPayload: SilentRequest = {
        scopes,
        account: activeAccount || accounts[0]
    }

    const authResult = await msalInstance.acquireTokenSilent(silentAuthPayload).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            msalInstance.acquireTokenPopup({ scopes })
            return
        }
        return undefined
    })

    return authResult?.accessToken || ""
}
